<template>
    <div class="cardAgency">
        <div class="cardAgency__block1">
            <span class="desctop">
                <div class="cardAgency__block1-back" @click="$router.back">
                    <img src="@/assets/icons/backArrow.png"/>
                    <p>{{ $t('back') }}</p>
                </div>
            </span>
            <p class="cardAgency__block1-desc">{{ agencyViewApi.show_case?.sub_title }}</p>
           <p class="cardAgency__block1-text" v-html="agencyViewApi?.show_case?.title"></p>
           <span class="desctop cardAgency__block1__filter" v-if="agencyViewApi?.text_show_case?.length">
                <div class="cardAgency__block1__filter-box" v-for="(item, index) in agencyViewApi?.text_show_case" :key="index">
                    <div></div>
                    <p>{{ item.title }}</p>
                </div>
           </span>
        </div>

        <!-- <div class="cardAgency__block2" v-if="agencyViewApi?.show_case?.image" alt="agencyViewApi?.show_case?.image" :style="{ backgroundImage: 'url(' + agencyViewApi?.show_case?.image + ')' }"> -->

        <img :src="agencyViewApi?.show_case?.image" class="cardAgency__block2" v-if="agencyViewApi?.show_case?.image"/>

        <div class="cardAgency__block3" >
            <p class="cardAgency__block3-p1" v-if="agencyViewApi?.show_case?.content" v-html="agencyViewApi?.show_case?.content"></p>
            <p class="cardAgency__block3-p2" v-if="agencyViewApi?.show_case?.first_content" v-html="agencyViewApi?.show_case?.first_content"></p>
        </div>

        <div class="cardAgency__block4" v-if="agencyViewApi?.show_case?.second_content">
            <div class="cardAgency__block4__content"  v-html="agencyViewApi?.show_case?.second_content">
            </div>
            <div class="cardAgency__block4__content2"  v-html="agencyViewApi?.show_case?.third_content">
            </div>
        </div>

        <div class="cardAgency__block5" v-if="agencyViewApi?.cases?.length">
            <p class="cardAgency__block5-title">{{ $t('cases') }}:</p>
            <div class="cardAgency__block5__content">
                <router-link :to="'/our-works/' + item.works_slug" class="cardAgency__block5__content__box" v-for="(item, idx) in agencyViewApi.cases" :key="idx">
                    <p class="cardAgency__block5__content__box-p1">0{{ ++idx }}.</p>
                    <div class="cardAgency__block5__content__box-p2">
                        <img :src="item.image" v-if="item.image"/>
                    </div>
                    <p class="cardAgency__block5__content__box-p3">{{ item.title }}</p>
                    <p class="cardAgency__block5__content__box-p4 desctop">{{ item.status }}</p>
                </router-link>
            </div>
        </div>

        <div class="cardAgency__block6">
            <div class="cardAgency__block6__content">
                <div class="cardAgency__block6__content__left">
                    <a :href="'tel:' + agencyViewApi.show_case?.phone" class="cardAgency__block6__content__left-p1">{{ agencyViewApi.show_case?.phone }}</a>
                    <p class="cardAgency__block6__content__left-p2">{{ agencyViewApi.show_case?.address }}</p>
                </div>
                <div class="cardAgency__block6__content-line"></div>
                <div class="cardAgency__block6__content__right">
                    <p  class="cardAgency__block6__content__right-p1">{{ agencyViewApi.show_case?.link }}</p>
                    <a :href="'mailto:' + agencyViewApi.show_case?.email" class="cardAgency__block6__content__right-p2">{{ agencyViewApi.show_case?.email }}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useHead } from "@vueuse/head"
import { computed, reactive } from "vue"
import { mapMutations } from 'vuex'

export default {
    data() {
        return {
            siteData: null,
            agencyViewApi: "",
            titleMeta: "",
            descMeta: "",
            keywordsMeta: "",
            block5: [
                {
                    title: "Енот-посудомойка",
                    icon: "case1",
                    desc: this.$t('online') + ' + ' + this.$t('offline')
                },
                {
                    title: "Shop Beeline",
                    icon: "case2",
                    desc: this.$t('online')
                },
                {
                    title: "Учим казахский язык",
                    icon: "case3",
                    desc: this.$t('online') + ' + ' + this.$t('offline')
                },
                {
                    title: "Ханская школа",
                    icon: "case4",
                    desc: this.$t('online') + ' + ' + this.$t('offline')
                },
                {
                    title: "Выдвигайся за HUAWEI",
                    icon: "case5",
                    desc: this.$t('online') + ' + ' + this.$t('offline')
                }
            ]
        }
    },

     methods: {
        ...mapMutations(["SET_LOADING"]),

        
        initializeSiteData() {
            this.siteData = reactive({
                title: this.titleMeta,
                description: this.descMeta,
                keywords: this.keywordsMeta
            });

            useHead({
                meta: [
                    {
                        name: computed(() => 'title'),
                        content: computed(() => this.siteData.title),
                    },
                    {
                        name: computed(() => 'description'),
                        content: computed(() => this.siteData.description),
                    },
                    {
                        name: computed(() => 'keywords'),
                        content: computed(() => this.siteData.keywords),
                    },
                ]
            });
        },

    },
    

    async created() {
      this.SET_LOADING(true)
      await this.$axios.post(`/V1/page/showcase/${this.$route.params.slug}`)
      .then((response) => {
        this.agencyViewApi = response.data;
        this.titleMeta = response.data.title;
        this.descMeta = response.data.meta_description;
        this.keywordsMeta = response.data.meta_keywords;
        this.SET_LOADING(false)
      })
      .catch((e) => {
          this.SET_LOADING(false)
      });
      
      await this.initializeSiteData();
    }
}
</script>
<style lang="scss" scoped>
.cardAgency {
    padding-bottom: 128px;
    @media screen and (max-width: 768px) {
        padding-bottom: 230px;
    }
    @media screen and (max-width: 454px) {
        padding-bottom: 200px;
    }
    &__block1 {
        height: fit-content;
        min-height: 28.58vw;
        margin: auto;
        display: flex;
        padding-top:  141px;
        flex-direction: column;
        @media screen and (max-width: 820px) and (min-height: 1180px) {
            padding-bottom: 35px;
        }
        @media screen and (max-width: 1152px) {
            padding-top: 90px
        }
        @media screen and (max-width: 1024px) {
            padding-top: 9.2%;
        }
        @media screen and (max-width: 768px) {
            justify-content: center;
            min-height: 304px;
            height: fit-content;
            padding-top: 160px;
            display: block;
        }
        &-back {
            cursor: pointer;
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 7px 13px;
            border: 1px solid #EB2127;
            border-radius: 49px;
            font-weight: 400;
            font-size: 14px;
            margin-left: 11%;
            @media screen and (max-width: 1200px) {
                position: relative;
                top: 21px;
            }
            img {
                transition: all 0.5s ease;
            }
            &:hover {
                transition: all 0.5s ease;
                background: #EB2127;
                color: white;
                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(9%) saturate(249%) hue-rotate(272deg) brightness(117%) contrast(100%);
                }
            }
        }
        &-desc {
            overflow: inherit;
            padding-top: 11px;
            color: #B3B3B3;
            text-align: center;
            font-weight: 400;
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
        }
        &-text {
            overflow: inherit;
            font-weight: 900;
            padding: 20px 0 0 0;
            text-transform: uppercase;
            text-align: center;
            margin-right: auto;
            margin-left: auto;
            width: 80%;
            font-weight: 900;
            font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 1.3393))), 50px);
            @media screen and (max-width: 1024px) {
                padding: 0 !important;
            }
            p {
                font-family: $CeraBlack;
            }
        }
        &__filter {
            margin-top: auto;
            margin-bottom: 50px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 20px;
            row-gap: 10px;
            font-weight: 400;
            font-size: 14px;
            @media screen and (max-width: 1024px) {
                width: 90%;
                flex-wrap: wrap;
                margin: auto;
                gap: 20px;
                row-gap: 5px;
                p {
                    width: max-content;
                }
            }
            &-box {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                &:nth-child(1) {
                    div {
                        display: none;
                    }
                }
                div {
                    border-radius: 50%;
                    height: 8px;
                    width: 8px;
                    background: #EB2127;
                }
            }
        }
    }
    &__block2 {
        height: fit-content;
        min-height: 28.58vw;
        width: 100%;
        object-fit: cover;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media screen and (max-width: 768px) {
            height: 240px;
            background-position: center;
        }
    }

    &__block3 {
        width: 60.4%;
        padding: 5.8% 0;
        height: fit-content;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        &-p1 {
            font-size: min(max(18px, calc(1.125rem + ((1vw - 3.2px) * 1.0714))), 30px);
            @media screen and (max-width: 768px) {
                width: 95%;
                margin: auto;
            }
        }
        &-p2 {
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
            @media screen and (max-width: 768px) {
                width: 91%;
                margin-left: 3%;
            }
        }
        @media screen and (max-width: 768px) {
            width: 90%;
            gap: 40px;
            padding: 16% 0 15% 0;
        }
    }

    &__block4 {
        padding: 4.3% 0 3% 0;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 40px;
        justify-content: center;
        background: #F2F2F2;
        @media screen and (max-width: 768px) {
            padding: 10% 0;
            gap: 35px;
        }
        &__content {
            margin: auto;
            width: 60.4%;
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
            @media screen and (max-width: 768px) {
                width: 85.5%;
                margin: auto;
            }
        }
        &__content2 {
            margin: auto;
            width: 60.4%;
            overflow: inherit;
            font-size: min(max(18px, calc(1.125rem + ((1vw - 3.2px) * 1.0714))), 30px);
            @media screen and (max-width: 768px) {
                width: 85.5%;
            }
        }
    }

    &__block5 {
        width: 60.4%;
        padding: 5.3% 0 7% 0;
        height: fit-content;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 768px) {
            width: 90%;
            padding: 105px 0 0 0;
        }
        &-title {
            color: #BDBDBDCC;
            font-weight: 400;
            font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 0.5357))), 30px);
            @media screen and (max-width: 768px) {
                margin-bottom: 23px;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            &__box {
                padding: 4.2% 20px 4.2% 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid rgba(221, 219, 219, 0.8);
                @media screen and (max-width: 768px) {
                    border-bottom: 1px solid transparent;
                    padding: 14% 0;
                    position: relative;
                    &::before {
                        content: "";
                        width: 120%;
                        position: absolute;
                        bottom: -1px;
                        height: 1px;
                        background: rgba(221, 219, 219, 0.8);
                    }
                }
                &-p1 {
                    width: fit-content;
                    color: #BDBDBDCC;
                    font-weight: 400;
                    font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
                    @media screen and (max-width: 768px) {
                        margin-left: 10px;
                    }
                }
                &-p2 {
                    width: 25%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    object-fit: cover;
                    @media screen and (max-width: 768px) {
                        width: 23%;
                        height: 30px;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    @media screen and (max-width: 820px) and (min-height: 1180px) {
                        height: 20px;
                        width: 19%;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
                &-p3 {
                    width: 45.99%;
                    display: flex;
                    align-items: start;
                    justify-content: start;
                    font-weight: 400;
                    font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.8036))), 24px);
                }
                &-p4 {
                    margin-left: auto;
                    align-items: flex-end;
                    justify-content: flex-end;
                    min-width: 20.15%;;
                    width: fit-content;
                    display: flex;
                    color: #BDBDBDCC;
                    font-weight: 400;
                    font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
                     @media screen and (max-width: 820px) and (min-height: 1180px) {
                        min-width: 27.15%;
                        align-items: flex-start; 
                        justify-content: flex-start;
                     }
                }
            }
        }
    }   

    &__block6 {
        background: #F2F2F2;
        padding: 4.3% 0;
        @media screen and (max-width: 768px) {
            padding: 10% 0;
        }
        &__content {
            margin: auto;
            width: 60.4%;
            display: flex;
            align-items: center;
            @media screen and (max-width: 820px) and (min-height: 1180px) {
             width: 70%;
            }
            @media screen and (max-width: 768px) {
                width: 90%;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                gap: 58px;
            }
            &__left {
                width: 49%;
                display: flex;
                flex-direction: column;
                gap: 15px;
                @media screen and (max-width: 768px) {
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                }
                &-p1 {
                    width: fit-content;
                    font-weight: 700;
                    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 0.8929))), 34px);
                }
                &-p2 {
                    font-weight: 400;
                    font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
                }
            }
            &-line {
                background: #000000;
                width: 0.5px;
                height: 110px;
                @media screen and (max-width: 768px) {
                    height: 1px;
                    width: 40%;
                    margin: auto;
                }
            }
            &__right {
                // width: 49%;
                display: flex;
                align-items: start;
                gap: 15px;
                margin: auto;
                flex-direction: column;
                justify-content: start;
                @media screen and (max-width: 768px) {
                    width: 100%;
                    margin: auto;
                    align-items: center;
                    justify-content: center;
                }
                &-p1 {
                    width: fit-content;
                    font-weight: 700;
                    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 0.8929))), 34px);
                }
                &-p2 {
                    font-weight: 400;
                    font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
                }

            }
        }
    }
}
</style>