<template>
    <div class="home">
        <span class="desctop">
            <div class="home__block1Background" :style="isFormat === 'image' ? { backgroundImage: 'url(' + homeApi?.banner?.image + ')' } : ''">
                <div class="home__block1">
                    <div class="home__block1-p1">
                        <h1 v-html="homeApi?.banner?.title"></h1>
                        <h1 v-html="homeApi?.banner?.sub_title"></h1>
                    </div>
                    <p class="home__block1-p2" v-html="homeApi?.banner?.text"></p>
                </div>
                <video :src="homeApi?.banner?.image" v-if="isFormat === 'video'" autoplay muted loop playsinline />
            </div>
        </span>

        <span class="mobile">
            <div class="home__block1Background" :style="isFormatMobile === 'image' ? { backgroundImage: 'url(' + homeApi?.banner?.mobile_image + ')' } : ''">
                <div class="home__block1">
                    <div class="home__block1-p1">
                        <h1 v-html="homeApi?.banner?.title"></h1>
                        <h1 v-html="homeApi?.banner?.sub_title"></h1>
                    </div>
                    <p class="home__block1-p2" v-html="homeApi?.banner?.text"></p>
                </div>
                <video :src="homeApi?.banner?.mobile_image" v-if="isFormatMobile === 'video'" autoplay muted loop playsinline />
            </div>
        </span>

        <div class="home__block2">
            <router-link to="/about-us" class="home__block2__left">
                <div class="home__block2__left__box desctop">
                    <p class="home__block2__left__box-p1">{{ $t('home.block2Title2') }}</p>
                    <p class="home__block2__left__box-p2">{{ $t('knowMore') }}</p>
                </div>
                <span class="mobile">
                    <div class="home__block2__left__boxMobile">
                        <p class="home__block2__left__boxMobile-p1">{{ $t('home.block2Title2') }}</p>
                        <img src="@/assets/icons/arrow90littleMobile.svg"/>
                    </div>
                </span>
            </router-link>

            <router-link to="/showcase" class="home__block2__right"  v-if="homeApi">
                <Swiper
                    class="home__block2__right__swiper"
                    :lazy="true" :loop="true"
                    :mousewheel="true"
                    :centeredSlides="true"
                    :speed="2000"
                    :breakpoints="{
                        1000: {
                            direction: 'vertical',
                            slidesPerView: 3,
                            spaceBetween: 50
                        },
                        768: {
                            direction: 'vertical',
                            slidesPerView: 3,
                            spaceBetween: 50
                        },
                        454: {
                            direction: 'horizontal',
                            slidesPerView: 2.3,
                            spaceBetween: 25
                        },
                        280: {
                            direction: 'horizontal',
                            slidesPerView: 2.3,
                            spaceBetween: 25
                        }
                    }"
                    :autoplay="{
                        delay: 1500,
                        disableOnInteraction: true,
                    }">
                    <SwiperSlide v-for="(item, index) in homeApi?.show_cases" :key="'image' + index"  class="home__block2__right__swiper__item">
                        <img :src="item.logo"/>
                    </SwiperSlide>
                </Swiper>

                <div class="home__block2__right__box desctop">
                    <p class="home__block2__right__box-p1">{{ $t('agenV') }}</p>
                    <p class="home__block2__right__box-p2">{{ $t('knowMore') }}</p>
                </div>

                <span class="mobile">
                    <div class="home__block2__right__boxMobile">
                        <p class="home__block2__right__boxMobile-p1">{{ $t('agenV') }}</p>
                        <img src="@/assets/icons/arrow90littleMobile.svg"/>
                    </div>
                </span>
            </router-link>

        </div>

        <div class="home__block3">
            <router-link to="/our-works" class="home__block3__left"  :style="{ backgroundImage: 'url(\'' + works[0]?.banner + '\')' }" >
                <div class="home__block3__left__box desctop">
                    <p class="home__block3__left__box-p1">{{ $t('home.block2Title1') }}</p>
                    <p class="home__block3__left__box-p2">{{ $t('knowMore') }}</p>
                </div>
                <span class="mobile">
                    <div class="home__block3__left__boxMobile" style="{}">
                        <p>{{ $t('home.block2Title1') }}</p>
                        <img src="@/assets/icons/arrow90littleMobileRed.svg"/>
                    </div>
                </span>
            </router-link>
            <div class="home__block3__right desctop">
                <div class="home__block3__right__top">
                    <router-link :to="'/our-works/' + works[1]?.slug" class="home__block3__right__top__block1" :style="{ backgroundImage: 'url(\'' + works[1]?.banner + '\')' }">
                        <div class="home__block3__right__top__block1__box">
                            <p class="home__block3__left__box-p1 font30x20">{{ works[1]?.title }}</p>
                        </div>
                    </router-link>
                    <router-link :to="'/our-works/' + works[2]?.slug" class="home__block3__right__top__block2" :style="{ backgroundImage: 'url(\'' + works[2]?.banner + '\')' }">
                        <div class="home__block3__right__top__block2__box">
                            <p class="home__block3__left__box-p1 font30x20">{{ works[2]?.title }}</p>
                        </div>
                    </router-link>
                </div>
                <router-link :to="'/our-works/' + works[3]?.slug" class="home__block3__right__bot" :style="{ backgroundImage: 'url(\'' + works[3]?.banner + '\')' }">
                    <div class="home__block3__right__bot__box">
                        <p class="home__block3__left__box-p1 font30x20">{{ works[3]?.title }}</p>
                    </div>
                </router-link>
            </div>
        </div>

        <div class="home__block4 desctop">
            <router-link :to="'/our-works/' + works[4]?.slug" class="home__block4__left" :style="{ backgroundImage: 'url(\'' + works[4]?.banner + '\')' }">
                <div class="home__block4__left__box">
                    <p class="home__block4__left__box-p1 font30x20">{{ works[4]?.title }}</p>
                </div>
            </router-link>
            <div class="home__block4__right">
                <router-link :to="'/our-works/' + works[5]?.slug" class="home__block4__right__top" :style="{ backgroundImage: 'url(\'' + works[5]?.banner + '\')' }">
                    <div class="home__block4__right__top__box">
                        <p class="home__block4__right__top__box-p1 font30x20">{{ works[5]?.title }}</p>
                    </div>
                </router-link>
                <router-link to="/our-works" class="home__block4__right__bot">
                    <img src="@/assets/icons/arrowRight.svg"/>
                </router-link>
            </div>
        </div>

        <div class="home__block5">
            <div class="home__block5__left">
                <p class="home__block5__left-title">{{ $t('home.news') }}</p>

                <div class="home__block5__left__content">
                    <news-card v-for="(item, index) in paginatedNews" :key="item" :newsValue="item" :indexProps="index" :lengthProps="paginatedNews.length"/>
                </div>
                <div class="home__block5__left-pagination">
                    <vue-awesome-paginate 
                    v-if="news.length > 2" 
                    :total-pages="totalPages" 
                    :limit="2" 
                    maxPagesShown="50"
                    v-model="currentPage" 
                    :items-per-page="2" 
                    :total-items="(news.length).toFixed(1)" 
                    :disabled-class="'disabled'" 
                    :active-class="'active'" 
                    :on-click="pageChanged"
                    />
                 </div>
            </div>
            <router-link to="/countries" class="home__block5__right">
                <div class="home__block5__right__box">
                    <p class="home__block5__right__box-p1">{{ $t('menu.countyOpp') }}</p>
                    <p class="home__block5__right__box-p2">{{ $t('knowMore') }}</p>
                </div>
            </router-link>
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { A11y, Autoplay, Mousewheel  } from "swiper";
import { Navigation, Pagination } from 'swiper';
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, Mousewheel])


import NewsCard from '@/components/NewsCardNew.vue';
import { useHead } from "@vueuse/head"
import { computed, reactive } from "vue"

import { mapMutations } from 'vuex';
export default {
    components: { Swiper, SwiperSlide, NewsCard },
    data() {
        return {
            works: "",
            homeApi: "",
            news: "",
            currentPage: 1,
            siteData: null,
            titleMeta: "",
            descMeta: "",
            keywordsMeta: ""
        }
    },
    computed: {
        paginatedNews() {
            const startIndex = (this.currentPage - 1) * 2;
            const endIndex = startIndex + 2;
            return this.news.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.news.length / 2);
        },
        isFormat() {
            const videoExtensions = ["mp4", "avi", "mov"]; // список расширений видеофайлов
            const imageExtensions = ["jpg", "jpeg", "png", "gif"]; // список расширений изображений

            const extension = this.homeApi?.banner?.image.split(".").pop().toLowerCase();

            if (videoExtensions.includes(extension)) {
                return "video";
            } else if (imageExtensions.includes(extension)) {
                return "image";
            } else {
                return "unknown";
            }
        },
        isFormatMobile() {
            const videoExtensions = ["mp4", "avi", "mov"]; // список расширений видеофайлов
            const imageExtensions = ["jpg", "jpeg", "png", "gif"]; // список расширений изображений

            const extension = this.homeApi?.banner?.mobile_image.split(".").pop().toLowerCase();

            if (videoExtensions.includes(extension)) {
                return "video";
            } else if (imageExtensions.includes(extension)) {
                return "image";
            } else {
                return "unknown";
            }
        }
    },
    async created() {
        this.SET_LOADING(true)
        await this.$axios.get(`/V1/page/home`)
        .then((response) => {
            this.homeApi = response.data;
            this.works = response.data.works;
            this.shuffleArray(this.works);
            this.news = response.data.news;
            this.titleMeta = response.data.title;
            this.descMeta = response.data.meta_description;
            this.keywordsMeta = response.data.meta_keywords;
            this.SET_LOADING(false)
        })
        .catch((e) => {
            this.SET_LOADING(false)
        });
        ;

        await this.initializeSiteData();
    },
    methods: {
        ...mapMutations(["SET_LOADING"]),

        initializeSiteData() {
            this.siteData = reactive({
                title: this.titleMeta,
                description: this.descMeta,
                keywords: this.keywordsMeta
            });

            useHead({
                meta: [
                    {
                        name: computed(() => 'title'),
                        content: computed(() => this.siteData.title),
                    },
                    {
                        name: computed(() => 'description'),
                        content: computed(() => this.siteData.description),
                    },
                    {
                        name: computed(() => 'keywords'),
                        content: computed(() => this.siteData.keywords),
                    },
                ]
            });
        },

        pageChanged(page) {
            this.currentPage = page;
            this.manualPageChange = false;
        },

        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.font30x20 {
    font-size: min(max(20px, calc(1.25rem + ((1vw - 3.2px) * 0.8929))), 30px) !important;
}
    
.home {
    margin-bottom: 120px;
    @media screen and (max-width: 768px) {
        margin-bottom: 250px;
    }
    @media screen and (max-width: 454px) {
        margin-bottom: 170px;
    }
    &__block1Background {
        background: white;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &::before {
            content: "";
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(180.07deg, rgba(235, 33, 39, 0.55) 0.06%, rgba(235, 33, 39, 0) 99.94%);
        }
        video {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &__block1 {
        position: relative;
        z-index: 2;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        justify-content: center;
        &-p1 {
            overflow: initial;
            color: white;
            text-align: center;
            font-weight: 700;
            @media screen and (max-width: 768px) {
                font-family: $CeraBlack;
                width: 90%;
            }
            @media screen and (max-width: 454px) {
                font-family: $CeraBlack;
                width: 85%;
            }
            @media screen and (max-width: 280px) {
                font-size: 30px;
                line-height: 30px;
            }
            h1 {
                text-transform: uppercase;
                overflow: initial;
                font-size: min(max(36px, calc(2.25rem + ((1vw - 3.2px) * 3.0357))), 70px);
            }
        }
        &-p2 {
            width: 90%;
            margin: auto;
            text-align: center;
            font-weight: 400;
            font-size: min(max(18px, calc(1.125rem + ((1vw - 3.2px) * 0.5357))), 24px);
            @media screen and (max-width: 768px) {
                width: 68.5%;
            }
        }
    }


    &__block2 {
        display: flex;
        height: 100vh;
        @media screen and (max-width: 768px) {
            height: 70vh;
        }
        @media screen and (max-width: 456px) {
            height: 50vh;
            flex-direction: column;
        }
        @media screen and (max-width: 820px) and (min-height: 1180px) {
            height: 50vh;
        }
        &__left {
            cursor: pointer;
            color: #FFFFFF;
            background: url('@/assets/images/img1.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 50%;
            display: flex;
            align-items: flex-end;
            @media screen and (max-width: 768px) {
                width: 100%;
                height: 100%;
            }
            span {
                width: 90%;
                margin-right: 8%;
                margin-left: 5%;
                margin-bottom: 12.5%;
                @media screen and (max-width: 768px) {
                    width: 100%;
                    margin-right: 0;
                    margin-left: 0;
                    margin-bottom: 0;
                    padding-right: 35px;
                    padding-left: 24px;
                    padding-bottom: 40px;
                }
            }
            &__boxMobile {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 24px;
                color: black;
                font-weight: 700;
                @media screen and (max-width: 280px) {
                    font-size: 20px;
                }
                @media screen and (max-width: 768px) {
                    &-p1 {
                        font-family: $CeraBold;
                        color: #000000;
                    }
                }
            }
            &__box {
                display: flex;
                flex-direction: column;
                gap: 23px;
                padding-left: 15%;
                padding-bottom: 11%;
                &-p1 {
                    font-family: $CeraMedium;
                    font-weight: 500;
                    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.4286))), 40px);
                }
                &-p2 {
                    font-family: $CeraBold;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 15px;
                }
            }
        }
        &__right {
            cursor: pointer;
            color: #231F20;
            background: white;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 50%;
            display: flex;
            flex-direction: column;
            padding-top: 5%;
            gap: 5%;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 768px) {
                width: 100%;
                height: 100%;
            }
            
            span {
                width: 90%;
                margin-right: 8%;
                margin-left: 5%;
                margin-bottom: 12.5%;
                @media screen and (max-width: 768px) {
                    width: 100%;
                    margin-right: 0;
                    margin-left: 0;
                    margin-bottom: 0;
                    padding-right: 35px;
                    padding-left: 24px;
                    padding-bottom: 40px;
                }
            }
            &__boxMobile {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 24px;
                color: black;
                font-weight: 700;
                
                @media screen and (max-width: 280px) {
                    font-size: 20px;
                }
                @media screen and (max-width: 768px) {
                    &-p1 {
                        font-family: $CeraBold;
                        color: #000000;
                    }
                }
            }
            &__swiper {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                width: 100%;
                .swiper-wrapper {
                    transition: all 0s ease !important;
                }
                .swiper-slide-active {
                    opacity: 1;
                    img {
                        transform: scale(1.5);
                        @media screen and (max-width: 768px) {
                            transform: scale(1.1);
                        }
                    }
                }
                &__item {
                    opacity: 0.3;
                    transition: all 0.5s ease;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    width: 20%;
                    height: auto;
                    @media screen and (max-width: 768px) {
                        // width: 100% !important;
                    }
                    img {
                        transition: all 0.5s ease;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        @media screen and (max-width: 768px) {
                            width: 90%;
                            height: 90%;
                        }
                    }
                    @media screen and (max-width: 768px) {
                        height: 100px;
                    }
                }
            }
            &__box {
                margin-right: auto;
                display: flex;
                flex-direction: column;
                gap: 23px;
                padding-left: 15%;
                padding-bottom: 11%;
                &-p1 {
                    font-family: $CeraMedium;
                    font-weight: 500;
                    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.4286))), 40px);
                }
                &-p2 {
                    font-family: $CeraBold;
                    font-weight: 700;
                    font-size: 15px;
                    text-transform: uppercase;
                }
            }
        }
    }

    &__block3 {
        display: flex;
        height: 100vh;
        @media screen and (max-width: 768px) {
            height: 50vh;
        }
        @media screen and (max-width: 820px) and (min-height: 1180px) {
            height: 50vh;
        }
        &__left {
            color: #FFFFFF;
            background: white;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 50%;
            display: flex;
            align-items: flex-end;
            position: relative;
            transition: all 0.5s ease;
            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg,rgb(0, 0, 0, 0.8),transparent 70%);
            }
            filter: grayscale(var(--value, 100%));
            &:hover {
                 filter: grayscale(var(--value, 0%));
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                filter: grayscale(var(--value, 0%));
            }
            
            span {
                width: 90%;
                margin-right: 8%;
                margin-left: 5%;
                margin-bottom: 12.5%;
                @media screen and (max-width: 768px) {
                    width: 100%;
                    margin-right: 0;
                    margin-left: 0;
                    margin-bottom: 0;
                    padding-right: 35px;
                    padding-left: 24px;
                    padding-bottom: 40px;
                }
            }
            &__boxMobile {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 24px;
                color: white;
                font-weight: 700;
                @media screen and (max-width: 768px) {
                    position: relative;
                    z-index: 2;
                    p {
                        font-family: $CeraBold;
                        color: #FFFFFF;
                    }
                }
            }
            &__box {
                text-shadow: 4px 2px 6px rgba(0,0,0,0.63);
                z-index: 2;
                display: flex;
                flex-direction: column;
                gap: 23px;
                padding-left: 15%;
                padding-bottom: 11%;
                &-p1 {
                    font-family: $CeraMedium;
                    font-weight: 500;
                    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.4286))), 40px);
                }
                &-p2 {
                    font-family: $CeraBold;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 15px;
                }
            }
        }
        &__right {
            width: 50%;
            display: flex;
            flex-direction: column;
            &__top {
                height: 50%;
                display: flex;
                &__block1 {
                    width: 50%;
                    color: #FFFFFF;
                    background: white;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    display: flex;
                    align-items: flex-end;
                    position: relative;
                    transition: all 0.5s ease;
                    filter: grayscale(var(--value, 100%));
                    &::before {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        transition: all 0.5s ease;
                        background: linear-gradient(0deg,rgb(0, 0, 0, 0.8),transparent 70%);
                    }
                    &:hover {
                        filter: grayscale(var(--value, 0%));
                        .home__block3__right__top__block1__box {
                            z-index: 2;
                            opacity: 1 !important;
                        }
                        &::before {
                            position: absolute;
                            content: "";
                            width: 100%;
                            height: 100%;
                            opacity: 1;
                        }
                    }
                    &__box {
                        text-shadow: 4px 2px 6px rgba(0,0,0,0.63);
                        transition: all 0.5s ease;
                        opacity: 0;
                        z-index: -1;
                        display: flex;
                        flex-direction: column;
                        gap: 0px;
                        padding-left: 8%;
                        padding-bottom: 9%;
                        padding-right: 5%;
                        width: 100%;
                        &-p1 {
                            font-weight: 800;
                            font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.4286))), 40px);
                        }
                        &-p2 {
                            text-transform: uppercase;
                            font-weight: 700;
                            font-size: 15px;
                        }
                    }
                }
                &__block2 {
                    width: 50%;
                    color: #FFFFFF;
                    background: white;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    display: flex;
                    align-items: flex-end;
                    position: relative;
                    transition: all 0.5s ease;
                    filter: grayscale(var(--value, 100%));
                    &::before {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        transition: all 0.5s ease;
                        background: linear-gradient(0deg,rgba(0, 0, 0, 0.8),transparent 70%);
                    }
                    &:hover {
                        filter: grayscale(var(--value, 0%));
                        .home__block3__right__top__block2__box {
                            z-index: 1;
                            opacity: 1 !important;
                        }
                        &::before {
                            position: absolute;
                            content: "";
                            width: 100%;
                            height: 100%;
                            opacity: 1;
                        }
                    }
                    &__box {
                        text-shadow: 4px 2px 6px rgba(0,0,0,0.63);
                        transition: all 0.5s ease;
                        opacity: 0;
                        z-index: -1;
                        display: flex;
                        flex-direction: column;
                        gap: 0px;
                        padding-left: 8%;
                        padding-bottom: 9%;
                        padding-right: 5%;
                        width: 100%;
                        &-p1 {
                            font-weight: 800;
                            font-size: min(max(20px, calc(1.25rem + ((1vw - 3.2px) * 0.8929))), 30px);
                        }
                        &-p2 {
                    text-transform: uppercase;
                            font-weight: 700;
                            font-size: 15px;
                        }
                    }
                }
            }
            &__bot {
                height: 50%;
                color: #FFFFFF;
                background: url('@/assets/images/img5.png');
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                display: flex;
                align-items: flex-end;
                position: relative;
                transition: all 0.5s ease;
                filter: grayscale(var(--value, 100%));
                &::before {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    transition: all 0.5s ease;
                    opacity: 0;
                }
                &:hover {
                    filter: grayscale(var(--value, 0%));
                    .home__block3__right__bot__box {
                        z-index: 1;
                        opacity: 1 !important;
                    }
                    &::before {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(0deg,rgb(0, 0, 0, 0.8),transparent 70%);
                        opacity: 1;
                    }
                }
                &__box {
                    text-shadow: 4px 2px 6px rgba(0,0,0,0.63);
                    transition: all 0.5s ease;
                    opacity: 0;
                    z-index: -1;
                    display: flex;
                    flex-direction: column;
                    gap: 0px;
                    padding-left: 5%;
                    padding-bottom: 9%;
                    padding-right: 5%;
                    width: 100%;
                    &-p1 {
                        font-weight: 800;
                        font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.4286))), 40px);
                    }
                    &-p2 {
                    text-transform: uppercase;
                        font-weight: 700;
                        font-size: 15px;
                    }
                }
            }
        }
    }

    &__block4 {
        height: 50vh;
        display: flex;
        @media screen and (max-width: 820px) and (min-height: 1180px) {
            height: 25vh;
        }
        &__left {
            height: 100%;
            width: 50%;
            color: #FFFFFF;
            background: url('@/assets/images/img5.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            align-items: flex-end;
            position: relative;
            transition: all 0.5s ease;
            filter: grayscale(var(--value, 100%));
            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                transition: all 0.5s ease;
                background: linear-gradient(0deg,rgb(0, 0, 0, 0.8),transparent 70%);
                opacity: 0;
            }
            &:hover {
                filter: grayscale(var(--value, 0%));
                .home__block4__left__box {
                    z-index: 1;
                    opacity: 1 !important;
                }
                &::before {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    opacity: 1;
                }
            }
            &__box {
                text-shadow: 4px 2px 6px rgba(0,0,0,0.63);
                transition: all 0.5s ease;
                opacity: 0;
                z-index: -1;
                display: flex;
                flex-direction: column;
                gap: 0px;
                padding-left: 15%;
                padding-bottom: 9%;
                padding-right: 5%;
                width: 100%;
                &-p1 {
                    font-weight: 800;
                    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.4286))), 40px);
                }
                &-p2 {
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 15px;
                }
            }
        }
        &__right {
            width: 50%;
            height: 100%;
            display: flex;
            &__top {
                height: 100%;
                width: 50%;
                color: #FFFFFF;
                background: white;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                display: flex;
                align-items: flex-end;
                position: relative;
                transition: all 0.5s ease;
                filter: grayscale(var(--value, 100%));
                &::before {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    transition: all 0.5s ease;
                    background: linear-gradient(0deg,rgb(0, 0, 0, 0.8),transparent 70%);
                    opacity: 0;
                }
                &:hover {
                    filter: grayscale(var(--value, 0%));
                    .home__block4__right__top__box {
                        z-index: 1;
                        opacity: 1 !important;
                    }
                    &::before {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        opacity: 1;
                    }
                }
                &__box {
                    transition: all 0.5s ease;
                    text-shadow: 4px 2px 6px rgba(0,0,0,0.63);
                    opacity: 0;
                    z-index: -1;
                    display: flex;
                    flex-direction: column;
                    gap: 0px;
                    padding-left: 15%;
                    padding-bottom: 20%;
                    padding-right: 5%;
                    width: 100%;
                    &-p1 {
                        font-weight: 800;
                        font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.4286))), 40px);
                    }
                    &-p2 {
                    text-transform: uppercase;
                        font-weight: 700;
                        font-size: 15px;
                    }
                }
            }
            &__bot {
                cursor: pointer;
                width: 50%;
                display: flex;
                align-items: center;
                background: red;
                justify-content: center;
                img {
                    // width: 35.68px;
                    // height: 35.68px;
                    width: 9.3%;
                    object-fit: contain;
                }
            }
        }
    }

    &__block5 {
        border-bottom: 0.5px solid #dfdfdf;
        height: 65.7vw;
        display: flex;
        overflow: hidden;
        @media screen and (max-width: 768px) {
            height: fit-content;
            border: none;
        }
        @media screen and (max-width: 820px) and (min-height: 1180px) {
            height: 70vh;
        }
        @media screen and (max-width: 454px) {
            height: fit-content;
            border: none;
        }
        &__left {
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &-title {
                font-family: $CeraBold;
                overflow: inherit;
                padding-top: 72px;
                padding-left: 15%;
                font-weight: 700;
                font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 0.4464))), 40px);
                @media screen and (max-width: 1024px) {
                    padding-top: 32px;
                }
                @media screen and (max-width: 768px) {
                    padding-left: 5%;
                }
            }

            &__content {
                height: 100%;
            }

            &-pagination {
                overflow: inherit;
                margin-bottom: 50px;
                display: flex;
                justify-content: center;
                @media screen and (max-width: 1350px) {
                    ul {
                        overflow: inherit;
                        margin-block-end: 0;
                        margin-block-start: 0;
                        li {
                            list-style-type: none;
                        }
                    }
                }
                @media screen and (max-width: 768px) {
                    margin: inherit;
                    width: auto;
                    margin-left: 1.625rem;
                    margin-top: -39px;
                    padding-right: 1.625rem;
                }

                @media screen and (max-width: 280px) {
                    margin-top: -2px;
                }
            }
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
        &__right {
            width: 50%;
            color: #231F20;
            background: url('@/assets/images/img8.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 50%;
            display: flex;
            align-items: flex-end;
            position: relative;
            transition: all 0.5s ease;
            @media screen and (max-width: 768px) {
                display: none;
            }
            &__box {
                display: flex;
                flex-direction: column;
                gap: 23px;
                padding-left: 15%;
                padding-bottom: 11%;
                &-p1 {
                    font-weight: 700;
                    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.4286))), 40px);
                }
                &-p2 {
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 15px;
                }
            }
        }
    }
}

.activeSwiper {
    transform: scale(3.5) !important;
}
</style>