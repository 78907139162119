<template>
    <div class="work">
        <div class="work__block1">
            <span class="desctop">
                <div class="work__block1-back" @click="$router.back">
                    <img src="@/assets/icons/backArrow.png"/>
                    <p>{{ $t('back') }}</p>
                </div>
            </span>
           <p class="work__block1-text" v-html="workApi?.title"></p>
        </div>
        
        <div class="work__block2" v-if="work">
            <work-card v-for="item in paginatedWork" :key="item" :workValue="item"  class="work__block2-box"/>
        </div>
        <p v-else class="container work__block1-text" style="margin-top: 50px;">{{ $t('notCases') }}</p>

        <div class="work__block3">
            <vue-awesome-paginate 
            v-if="work.length > 6" 
            :total-pages="totalPages" 
            :limit="6" 
            v-model="currentPage" 
            :items-per-page="6" 
            :total-items="(work.length).toFixed(1)" 
            :disabled-class="'disabled'" 
            :on-click="pageChanged"
            />
        </div>
    </div>
</template>
<script>
import work from '@/data/work.json'
import WorkCard from '@/components/WorkCard.vue'
import { useHead } from "@vueuse/head"
import { computed, reactive } from "vue"
import { mapMutations } from 'vuex'
export default {
    components: { WorkCard },
    data() {
        return {
            work: [],
            currentPage: 1,
            siteData: null,
            workApi: "",
            titleMeta: "",
            descMeta: "" ,
            keywordsMeta: ""
        }
    },

    methods: {
        ...mapMutations(["SET_LOADING"]),

        
        initializeSiteData() {
            this.siteData = reactive({
                title: this.titleMeta,
                description: this.descMeta,
                keywords: this.keywordsMeta
            });

            useHead({
                meta: [
                    {
                        name: computed(() => 'title'),
                        content: computed(() => this.siteData.title),
                    },
                    {
                        name: computed(() => 'description'),
                        content: computed(() => this.siteData.description),
                    },
                    {
                        name: computed(() => 'keywords'),
                        content: computed(() => this.siteData.keywords),
                    },
                ]
            });
        },


        loadProduct() {
            if (this.$route.query.page) {
                this.pageChanged(this.$route.query.page);
            }
        },

        pageChanged(page) { 
            this.currentPage = page;
            this.manualPageChange = false;
            this.$router.push(`/our-works?page=${page}`)
        },

        changePage() {
            if (this.manualPageChange) {
                this.currentPage++;
                if (this.currentPage > this.totalPages) {
                    this.currentPage = 1; 
                }
            }
        }
    },
    
    computed: { 
        paginatedWork() { 
            const startIndex = (this.currentPage - 1) * 6; 
            const endIndex = startIndex + 6; 
            return this.work.slice(startIndex, endIndex); 
        }, 
        totalPages() { 
            return Math.ceil(this.work.length / 6); 
        }, 
    },

    async created() {
        
      this.loadProduct();


      this.SET_LOADING(true)
      await this.$axios.get(`/V1/page/work`)
      .then((response) => {
        this.workApi = response.data;
        this.work = response.data.works;
        this.titleMeta = response.data.title;
        this.descMeta = response.data.meta_description;
        this.keywordsMeta = response.data.meta_keywords;
        this.SET_LOADING(false)
      })
      .catch((e) => {
          this.SET_LOADING(false)
      });
      
      await this.initializeSiteData();
    }
    
}
</script>
<style lang="scss" scoped>
.work {
    padding-bottom: 133px;
    @media screen and (max-width: 768px) {
        padding-bottom: 200px;
    }
    &__block1 {
        min-height: 28.58vw;
        height: fit-content;
        padding-top:  141px;
        margin: auto;
        display: flex;
        gap: 36px;
        flex-direction: column;
        border-bottom: 0.5px solid #BDBDBD;
        @media screen and (max-width: 820px) and (min-height: 1180px) {
            padding-bottom: 35px;
        }
        @media screen and (max-width: 1152px) {
            padding-top: 90px
        }
        @media screen and (max-width: 1024px) {
            padding-top: 10% !important;
            gap: 7% !important;
        }
        @media screen and (max-width: 768px) {
            justify-content: center;
            height: 370px;
            gap: 17%;
            min-height: fit-content;
            padding-top: 145px;
        }
        &-back {
            cursor: pointer;
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 7px 13px;
            border: 1px solid #EB2127;
            border-radius: 49px;
            font-weight: 400;
            font-size: 14px;
            margin-left: 11%;
            @media screen and (max-width: 1200px) {
                position: relative;
                top: 21px;
            }
            img {
                transition: all 0.5s ease;
            }
            &:hover {
                transition: all 0.5s ease;
                background: #EB2127;
                color: white;
                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(9%) saturate(249%) hue-rotate(272deg) brightness(117%) contrast(100%);
                }
            }
        }
        &-text {
            text-transform: uppercase;
            text-align: center;
            font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 1.3393))), 50px);
            font-family: $CeraBlack;
            font-weight: 900;
            overflow: inherit;
            line-height: 50px;
            @media screen and (max-width: 768px) {
                line-height: 35px;
            }
        }
    }

    &__block2 {
        display: flex;
        flex-wrap: wrap;
        gap: 0.1%;
        row-gap: 1px;
        &-box {
            width: 49.95%;
            height: 33.66vw;
            @media screen and (max-width: 768px) {
                height: 320px;
                width: 100%;
            }
        }
    }

    &__block3 {
        margin-left: 8%;
        margin-top: 71px;
        display: flex;
        gap: 32px;
        font-weight: 700;
        font-size: 14px;
        color: #BDBDBD;
        @media screen and (max-width: 768px) {
            margin: inherit;
            width: auto;
            margin-left: 1.625rem;
            margin-top: 23px;
            padding-right: 1.625rem;
        }
        p {
            cursor: pointer;
        }
    }
}
</style>