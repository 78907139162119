<template>
    <div class="background animated fadeIn" v-scroll @click="closeCoopSuccess(false)">
        <div class="container">
            <div class="coop animated fadeInLogin"  @click.stop>
                <p class="coop-title" v-html="$t('coopSuccess')"></p>
                <button class="coop__button" @click="closeCoopSuccess(false)">
                    <p>{{ $t('closeText') }}</p>
                    <img src="@/assets/icons/close.svg"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        closeCoopSuccess(value) {
            this.$store.commit('SET_MODAL_COOP__SUCCESS', value);
        }
    }
}
</script>

<style lang="scss" scoped>
.background {
    overflow: auto;
    color: black;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.coop {
    background: url('@/assets/images/modalback.png');
    background-position: center;
    margin: 30px 0;
    position: relative;
    height: 800px;
    width: 800px;
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
        width: 95%;
        height: 450px;
    }
    &-title {
        color: #FFFFFF;
        width: 70%;
        font-weight: 700;
        font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.4286))), 40px);
        text-align: center;
        @media screen and (max-width: 768px) {
            width: 80%;
        }
    }
    &__button {
        cursor: pointer;
        color: #EB2127;
        font-weight: 700;
        font-size: 15px;
        border: none;
        outline: none;
        padding: 13px 20px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.fadeIn {
    animation-name: fadeIn;
}

.fadeInLogin {
    animation-name: fadeInLogin;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInLogin {
    from {
        transform: scale(0.5);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}</style>