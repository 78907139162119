<template>
  <transition name="bounce">
    <div class="menuMobileBackground" v-scroll v-if="navbarValue">
        <div class="menuMobile container1">
          <div class="menuMobile__top">
              <router-link to="/" class="header__left">
                <img :src="`https://new.dasm.kz` + blackLogo"/>
              </router-link>

              <select v-model="getLang" @change="getLangFunction($event.target.value)" class="selectLang">
                  <option v-for="item in languages" :key="item" :value="item.value">{{ item.title }}</option>
              </select>

              <button class="hamburger hamburger--spin hamburgerMenu closeHumber" @click="navbarValue = !navbarValue" :class="{'is-active': navbarValue}">
                  <span class="hamburger-box" :class="{'hamburger-boxActive': navbarValue}">
                  <span class="hamburger-inner"></span>
                  </span>
              </button> 
          </div>

          <div class="menuMobile__content">
              <template v-for="item in menu" :key="item.id">

                  <router-link :to="'/' + item.slug" v-if="item.slug !== 'https://career.dasm.kz'">
                      <p>{{ item.title }}</p>
                      <div></div>
                  </router-link>

                  <span v-if="item.slug === 'https://career.dasm.kz'">
                    <a :href="item.slug">{{ item.title }}</a>
                    <div></div>
                  </span>
                  
                </template>
                
          </div>

          <div class="header__right">
            <a :href="item.link" target="_blank" class="header__right-box" v-for="(item, index) in social" :key="index" :class="'header__right-box' + ++index">
                <img :src="item.image" v-if="item.image"/>
            </a>
          </div>
        </div>
    </div>
  </transition>




  <div class="container1">
    <div class="header" :class="{mainView: $route.path === '/'}">
        <router-link to="/" class="header__left" v-if="whiteLogo">
          <img :src="whiteLogo" v-if="$route.path === '/'"/>
          <img :src="`https://new.dasm.kz` + blackLogo" v-else/>
        </router-link>
        <router-link to="/" class="header__leftNot" v-else>
            <img src="@/assets/logoWhite.png"/>
        </router-link>
        <span class="desctop">
          <div class="header__mid" :class="{'header__mid-KZ': getLang == 'kz', 'header__mid-RU': getLang == 'ru', 'header__mid-EN': getLang == 'en' }">

              <template v-for="item in menu" :key="item.id">

                <router-link :to="'/' + item.slug" v-if="item.slug !== 'https://career.dasm.kz'">
                    <p>{{ item.title }}</p>
                    <div></div>
                </router-link>

                <span v-if="item.slug === 'https://career.dasm.kz'" class="header__mid-a">
                  <a :href="item.slug">{{ item.title }}</a>
                  <div></div>
                </span>
              </template>

              <select v-model="getLang" @change="getLangFunction($event.target.value)" class="selectLang" v-if="menu">
                  <option v-for="item in languages" :key="item" :value="item.value">{{ item.title }}</option>
              </select>
          </div>
        </span>
        <span class="desctop">
          <div class="header__right" :class="{ 'header__right-KZ': getLang == 'kz', 'header__right-RU': getLang == 'ru', 'header__right-EN': getLang == 'en' }">
            <a :href="item.link" target="_blank" class="header__right-box" v-for="(item, index) in social" :key="index" :class="'header__right-box' + ++index">
                <img :src="item.image" v-if="item.image"/>
            </a>
          </div>
        </span>

        <span class="mobile">
          <div class="menu">
              <button class="hamburger hamburger--spin hamburgerMenu" @click="navbarValue = !navbarValue" :class="{'is-active': navbarValue}">
                <span class="hamburger-box" :class="{'hamburger-boxActive': navbarValue}">
                <span class="hamburger-inner"></span>
                </span>
            </button> 
          </div>
        </span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    data() {
      return {
            menu: "",
            blackLogo: "",
            whiteLogo: "",
            social: "",
            navbarValue: false,
            languages: [
                { id: 1, value: "ru", title: "RU"},
                { id: 1, value: "kz", title: "KZ"},
                { id: 1, value: "en", title: "EN"},
            ],
      }
  },
    watch: {
        $route() {
            this.navbarValue = false
        }
    },
  methods: {
      ...mapMutations(["UPDATE_LANG", "SET_LOADING"]),
      getLangFunction(option) {
        this.$i18n.locale = option;
        this.UPDATE_LANG(option);
        // this.$axios.defaults.params = {
        //     lang: this.$store.state.lang,
        // };
        location.reload();
      },
  },
  async created() {
    this.SET_LOADING(true);

    try {
      const [menuResponse, blackLogoResponse, whiteLogoResponse, socialResponse] = await Promise.all([
        this.$axios.get(`/V1/menu`),
        this.$axios.get(`/V1/black-logo`),
        this.$axios.get(`/V1/white-logo`),
        this.$axios.get(`/V1/social`)
      ]);

      this.menu = menuResponse.data.menu;
      this.blackLogo = blackLogoResponse.data.black_logo;
      this.whiteLogo = whiteLogoResponse.data.white_logo;
      this.social = socialResponse.data.cooperate.social;

      this.SET_LOADING(false);
    } catch (error) {
      // Handle errors here
      console.error(error);
      this.SET_LOADING(false);
    }
  },
  computed: {
      ...mapGetters(['getLang'])
  },
}
</script>
<style lang="scss" scoped>

.menuMobileBackground {
  position: fixed;
  padding: 3px 0 60px 0;
  width: 100%;
  top: 0%;
  height: 100%;
  z-index: 10;
  background: white;
}

.menuMobile {
  display: flex; 
  align-items: start;
  flex-direction: column;
  gap: 32px;
  padding-left: 10%;
  padding-right: 11.28%;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 7px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 21px;
  }
}

.menu {
  @media screen and (max-width: 768px) {
    position: relative;
    top: -7px;
  }
}

.header__mid-KZ {
  // font-size: 11px !important;
}
.closeHumber {
  transition: all 0.5s ease;
  &:hover {
    rotate: 360deg;
  }
}
.container1 {
  margin: auto;
  width: 77%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.selectLang {
  border: none;
  background: transparent;
  outline: none;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    color: black;
  }
  option {
    background: white;
    color: black;
  }
  option:checked {
      display: none;
  }
}
.mainView {
  .hamburger-inner {
      background: white !important;
      transition-duration: 0.22s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &:before {
      background: white !important;
      transition: top 0.1s 0.25s ease-in,
      opacity 0.1s ease-in;
      }

    &:after {
      background: white !important;
      transition: bottom 0.1s 0.25s ease-in,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
  .hamburger-boxActive {
  span {
    background: white !important;
    &:before {
      background: white !important;
    }
    &::after {
      background: white !important;
    }
  }
  }
  .selectLang {
    border: none;
    background: transparent;
    outline: none;
    option {
      background: red;
      color: white;
    }
    option:checked {
        display: none;
    }
  }
  .header__mid {
    a, .header__mid-a {
      color: white !important;
      &:hover {
        div {
          width: 100%;
          background: white !important;
        }
      }
    }
    select {
      color: white !important;
    }
  }
  .header__right-box {
    border: 1px solid white !important;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(299deg) brightness(102%) contrast(102%);
    }
  }
}

.header {
  padding: 38px 0 18px 0;
  z-index: 3;
  display: flex;
  width: inherit;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  margin: auto;
  @media screen and (max-width: 1366px) {
      padding: 18px 0 18px 0; 
  }
  @media screen and (max-width: 768px) {
    margin-top: 7px;
    padding: 0px 0;
    align-items: flex-end;
    padding-left: 10%;
    padding-right: 11.28%;
  }
  &__left {
    height: 70px;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  &__leftNot {
    opacity: 0;
  }
  &__mid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    row-gap: 5px;
    font-weight: 400;
    font-size: 14px;
    padding: 0 30px;

    @media screen and (max-width: 1540px) {
        gap: 30px;
        font-size: 12px;
        row-gap: 5px;
    }
    div {
      width: 1%;
      margin: auto;
      height: 2px;
      transition: all 0.5s ease;
      background: transparent;
    }
    a, .header__mid-a {
      &:hover {
        div {
          width: 100%;
          background: red;
        }
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
    gap: 28px;
    // position: relative;
    // top: 3px;
    @media screen and (max-width: 768px) {
      width: 90%;
      margin: auto;
      justify-content: space-between;
      gap: 0;
      position: relative;
      top: 0px;
    }
    &-box {
      height: 28px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid black;
      border-radius: 50%;
      transition: all 0.5s ease;
      @media screen and (max-width: 768px) {
        height: 43px;
        width: 43px;
      }
      img {
        filter: brightness(0) saturate(100%);
        transition: all 0.5s ease;
        @media screen and (max-width: 768px) {
          object-fit: contain;
        }
      }
      &:hover {
        background: #0a66c2;
        border: 1px solid transparent !important;
        transform: scale(1.25);
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(67%) saturate(2%) hue-rotate(170deg) brightness(109%) contrast(101%);
        }
      }
    }
    &-box2 {
      &:hover {
        background: #E4405F !important;
        border: 1px solid transparent !important;
        transform: scale(1.25);
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(67%) saturate(2%) hue-rotate(170deg) brightness(109%) contrast(101%);
        }
      }
    }
    &-box3 {
      &:hover {
        background: #3b5998 !important;
        border: 1px solid transparent !important;
        transform: scale(1.25);
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(67%) saturate(2%) hue-rotate(170deg) brightness(109%) contrast(101%);
        }
      }

    }
  }
}




$hamburger-padding-x           : 15px;
$hamburger-padding-y           : 15px;
$hamburger-layer-width         : 40px;
$hamburger-layer-height        : 4px;
$hamburger-layer-spacing       : 6px;
$hamburger-layer-color         : #000;
$hamburger-layer-border-radius : 4px;
$hamburger-hover-opacity       : 0.7;
$hamburger-active-layer-color  : $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

.hamburger {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  cursor: pointer;
  &:hover {
    background: none;
  }

  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none;

  &.is-active {
    .hamburger-inner,
    .hamburger-inner:before,
    .hamburger-inner:after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.hamburger-box {
  width: 35px;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: flex;
  opacity: 1;
  align-items: center;
  justify-content: center;
  position: relative;
}
.hamburger-boxActive {
  span {
    background: black !important;
    &:before {
      background: black !important;
    }
    &::after {
      background: black !important;
    }
  }
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &:before,
  &:after {
  width: 35px;
    height: 5px;
    background-color: $hamburger-layer-color;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &:before,
  &:after {
    content: "";
    display: block;
  }

  &:before {
    top: -10px;
  }

  &:after {
    bottom: -10px;
  }
}

.hamburger--spin {
  .hamburger-inner {
    background: black;
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &:before {
    background: black;
    transition: top 0.1s 0.25s ease-in,
    opacity 0.1s ease-in;
    }

  &:after {
  background: black;
  transition: bottom 0.1s 0.25s ease-in,
  transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}

&.is-active {
  .hamburger-inner {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &:before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out,
      opacity 0.1s 0.12s ease-out;
    }

  &:after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out,
  transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
}
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    // background: transparent;
    opacity: 0.5;
    top: -100%;
  }
  100% {
    // background: white;
    opacity: 1;
    top: 0;
  }
}

.header__mid-RU {
  @media screen and (max-width: 1375px) {
    position: relative;
    top: 14px;
  }
}
.header__right-RU {
  @media screen and (max-width: 1375px) {
    position: relative;
    top: 3px;
  }
}


.header__mid-KZ {
  @media screen and (max-width: 1482px) {
    position: relative;
    top: 14px;
  }
}
.header__right-KZ {
  @media screen and (max-width: 1482px) {
    position: relative;
    top: 3px;
  }
}

.header__mid-EN {
  @media screen and (max-width: 1412px) {
    position: relative;
    top: 14px;
  }
}
.header__right-EN {
  @media screen and (max-width: 1412px) {
    position: relative;
    top: 3px;
  }
}
</style>