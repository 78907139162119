<template>
    <div class="workCard">
        <div class="workCard__block1">
            <span class="desctop">
                <div class="workCard__block1-back" @click="$router.back">
                    <img src="@/assets/icons/backArrow.png"/>
                    <p>{{ $t('back') }}</p>
                </div>
            </span>
            <p class="workCard__block1-desc">{{ $t('case') }}</p>
           <p class="workCard__block1-text">{{ workViewApi?.work?.title }}</p>
           <span class="desctop workCard__block1__filter">
                <div class="workCard__block1__filter-box" v-for="(item, index) in workViewApi?.thematic_work" :key="index">
                    <div></div>
                    <p>{{ item.title }}</p>
                </div>
           </span>
        </div>

        <div class="workCard__block2" :style="{ backgroundImage: 'url(' + workViewApi?.work?.image + ')' }">

        </div>

        <div class="workCard__block3">
            <p class="workCard__block3-p1" v-if="workViewApi?.work?.first_content" v-html="workViewApi?.work?.first_content"></p>
            <p class="workCard__block3-p2" v-if="workViewApi?.work?.second_content" v-html="workViewApi?.work?.second_content"></p>
        </div>
        
        <div class="workCard__block4" v-if="workViewApi?.work?.youtube_link">
            <div class="workCard__block4__content">
                <iframe width="560" height="315" :src="workViewApi?.work?.youtube_link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>

        <div class="workCard__block5">
            <div class="workCard__block5__left">
                <img :src="workViewApi?.work?.third_image"/>
            </div>
            <div class="workCard__block5__right" v-if="workViewApi?.work?.third_content" v-html="workViewApi?.work?.third_content">
            </div>
        </div>

        <div class="workCard__block6 desctop">
            <p class="workCard__block6-title">{{ $t('otherCases') }}</p>
            <swiper
                class="workCard__block6__swiper"
                :lazy="true" :loop="false"
                :mousewheel="true"
                :breakpoints="{
                    1400: {
                    slidesPerView: 2,
                    spaceBetween: 1
                    },
                    768: {
                    slidesPerView: 2,
                    spaceBetween: 1
                    }
                }"
                :speed="1200"
                :autoplay="{
                    delay: 2500
                }"
                >
                <swiper-slide  v-for="(item) in allWorks" :key="item" >
                    <WorkCard :workValue="item" class="workCard__block6__swiper-box"/>
                </swiper-slide>
            </swiper>
        </div>

        <div class="block6Mobile mobile">
            <p class="workCard__block6-title">{{ $t('otherCases') }}</p>
            <div class="block6Mobile__card"  v-if="allWorksMobile[0]" :style="{ backgroundImage: 'url(\'' + allWorksMobile[0]?.banner + '\')' }">
                <router-link :to="'/our-works/' + allWorksMobile[0]?.slug" class="block6Mobile__card-box">
                    <p  v-html="allWorksMobile[0].title"></p>
                    <img src="@/assets/icons/arrow90deg.png"/>
                </router-link>
            </div>

            <div class="block6Mobile__card"  v-if="allWorksMobile[1]" :style="{ backgroundImage: 'url(\'' + allWorksMobile[1]?.banner + '\')' }">
                <router-link :to="'/our-works/' + allWorksMobile[1]?.slug" class="block6Mobile__card-box">
                    <p  v-html="allWorksMobile[1].title"></p>
                    <img src="@/assets/icons/arrow90deg.png"/>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide,} from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"; 

import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination } from 'swiper';
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay])
import WorkCard from "@/components/WorkCard.vue";


import { useHead } from "@vueuse/head"
import { computed, reactive } from "vue"
import { mapMutations } from 'vuex';

export default {
    components: { Swiper, SwiperSlide, WorkCard },
    data() {
        return {
            work: [],
            allWorks: [],
            allWorksMobile: [],
            siteData: null,
            workViewApi: "",
            titleMeta: "",
            descMeta: "",
            keywordsMeta: ""
        }
    },

    methods: {
        ...mapMutations(["SET_LOADING"]),

        
        initializeSiteData() {
            this.siteData = reactive({
                title: this.titleMeta,
                description: this.descMeta,
                keywords: this.keywordsMeta
            });

            useHead({
                meta: [
                    {
                        name: computed(() => 'title'),
                        content: computed(() => this.siteData.title),
                    },
                    {
                        name: computed(() => 'description'),
                        content: computed(() => this.siteData.description),
                    },
                    {
                        name: computed(() => 'keywords'),
                        content: computed(() => this.siteData.keywords),
                    },
                ]
            });
        },

        
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
        }
    },
    
    watch: {
        $route() {
            this.SET_LOADING(true)
            this.$axios.post(`/V1/page/work/${this.$route.params.slug}`)
            .then((response) => {
                this.workViewApi = response.data;
                this.titleMeta = response.data.title;
                this.descMeta = response.data.meta_description;
                this.keywordsMeta = response.data.meta_keywords;
                this.SET_LOADING(false)
            })
            .catch((e) => {
                this.SET_LOADING(false)
            });
        }
    },

    async created() {
      this.SET_LOADING(true)
      await this.$axios.post(`/V1/page/work/${this.$route.params.slug}`)
      .then((response) => {
        this.workViewApi = response.data;
        this.titleMeta = response.data.meta_keywords;
        this.descMeta = response.data.meta_description;
        this.SET_LOADING(false)
      })
      .catch((e) => {
          this.SET_LOADING(false)
      });

        this.SET_LOADING(true)
        await this.$axios.get(`/V1/page/work`)
        .then((response) => {
            this.allWorks = response.data.works;
            this.allWorksMobile = response.data.works;
            this.shuffleArray(this.allWorksMobile);
            
            this.SET_LOADING(false)
        })
        .catch((e) => {
            this.SET_LOADING(false)
        });
        
      await this.initializeSiteData();
    }
}
</script>
<style lang="scss" scoped>
.block6Mobile {
   &__card {
        margin-top: 2px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        &:nth-child(2) {
            margin-top: 32px;
        }
        &::before {
            position: absolute;
            content: "";
            z-index: 1;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg,rgb(0, 0, 0, 0.8),transparent 70%);
        }

        &-box {
            padding: 0 44px 29px 39px;
            height: 100vw;
            width: 100%;
            position: relative;
            z-index: 2;
            display: flex;
            align-items: flex-end;
            gap: 30px;
            justify-content: space-between;
            color: white;
            font-weight: 900;
            font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 0.5357))), 30px);
            p {
                text-shadow: 4px 2px 6px rgba(0,0,0,0.5);
                transition: all 0.5s ease;
            }
            img {
                width: 23px;
                height: 23px;
                object-fit: contain;
            }
        }
   }
}
.workCard {
    padding-bottom: 180px;
    @media screen and (max-width: 768px) {
        padding-bottom: 250px;
    }
    @media screen and (max-width: 454px) {
        padding-bottom: 210px;
    }
    &__block1 {
        height: fit-content;
        min-height: 28.58vw;
        margin: auto;
        display: flex;
        padding-top: 141px;
        flex-direction: column;
        @media screen and (max-width: 1152px) {
            padding-top: 90px
        }
        @media screen and (max-width: 1024px) {
            padding-top: 9.2%;
        }
        @media screen and (max-width: 768px) {
            justify-content: center;
            min-height: 304px;
            gap: 10px;
            height: fit-content;
            padding-top: 145px;
            padding-bottom: 60px;
        }
        &-back {
            cursor: pointer;
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 7px 13px;
            border: 1px solid #EB2127;
            border-radius: 49px;
            font-weight: 400;
            font-size: 14px;
            margin-left: 11%;
            @media screen and (max-width: 1200px) {
                position: relative;
                top: 21px;
            }
            img {
                transition: all 0.5s ease;
            }
            &:hover {
                transition: all 0.5s ease;
                background: #EB2127;
                color: white;
                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(9%) saturate(249%) hue-rotate(272deg) brightness(117%) contrast(100%);
                }
            }
        }
        &-desc {
            overflow: inherit;
            padding-top: 11px;
            color: #B3B3B3;
            text-align: center;
            font-weight: 400;
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
            @media screen and (max-width: 1024px) {
                padding-top: 0.1% !important;
            }
        }
        &-text {
            overflow: inherit;
            padding: 20px 0 0 0;
            text-transform: uppercase;
            text-align: center;
            font-weight: 900;
            margin-right: auto;
            margin-left: auto;
            width: 80%;
            line-height: 50px;
            font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 1.3393))), 50px);
            @media screen and (max-width: 400px) {
                font-size: min(max(30px, calc(1.875rem + ((1vw - 3.2px) * 1.7857))), 50px);
            }
            font-family: $CeraBlack;
            font-weight: 900;
            @media screen and (max-width: 768px) {
                line-height: 35px;
            }
            @media screen and (max-width: 1024px) {
                width: 90%;
                padding: 0 !important;
            }
            @media screen and (max-width: 280px) {
                font-size: 25px;
                line-height: 25px;
            }
        }
        &__filter {
            // margin-top: auto;
            margin-top: 78px;
            margin-bottom: 50px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 20px;
            row-gap: 10px;
            font-weight: 400;
            font-size: 14px;
            @media screen and (max-width: 768px) {
                margin-top: auto;
            }
            &-box {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                &:nth-child(1) {
                    div {
                        display: none;
                    }
                }
                div {
                    border-radius: 50%;
                    height: 8px;
                    width: 8px;
                    background: #EB2127;
                }
            }
        }
    }
    &__block2 {
        height: fit-content;
        min-height: 457px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media screen and (max-width: 1240px) {
            height: fit-content;
            min-height: 28.58vw;
        }
        @media screen and (max-width: 768px) {
            height: 245px;
            background-position: center;
        }
    }

    &__block3 {
        width: 63%;
        margin: auto;
        padding: 3.8% 0 5.1% 0;
        @media screen and (max-width: 768px) {
            width: 90%;
            padding: 0;
            margin-top: 50px;
        }
        &-p1 {
            margin-bottom: 50px;
            font-weight: 400;
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
            @media screen and (max-width: 768px) {
                margin-bottom: 45px;
            }
        }
        &-p2 {
            font-weight: 50;
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 1.3393))), 30px);
        }
    }

    &__block4 {
        width: 81.39%;
        height: 42.24vw;
        background: rgb(246, 246, 246);
        border-radius: 64px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 768px) {
            height: 165px;
            width: 90%;
            margin-top: 45px;
            margin-bottom: 50px;
            border-radius: 9px;
        }
        &__content {
            height: 80.78%;
            // background: linear-gradient(180deg, rgba(1, 1, 1, 0.6) 0%, rgba(1, 1, 1, 0.9375) 100%);
            border-radius: 45px;
            width: 75.59%;
            overflow: hidden;
            margin: auto;
            @media screen and (max-width: 768px) {
                border-radius: 9px;
                width: 80%;
            }
            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__block5 {
        width: 63%;
        margin: auto;
        padding: 4.3% 0 10% 0;
        display: flex;
        align-items: center;
        gap: 59px;
        justify-content: space-between;
         @media screen and (max-width: 820px) and (min-height: 1180px) {
            flex-direction: column;
            gap: 25px;
         }
        @media screen and (max-width: 768px) {
            width: 90%;
            padding: 0% 0 22% 0;
            flex-direction: column;
            gap: 53px;
        }
        &__left {
            width: 50.98%;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 820px) and (min-height: 1180px) {
                width: 100%;
                height: 250px;
                border-radius: 10px;
                overflow: hidden;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                height: 58vw;
            }
            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: contain;
                @media screen and (max-width: 820px) and (min-height: 1180px) {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                @media screen and (max-width: 768px) {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        &__right {
            font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.1786))), 18px);
            width: 42%;
            @media screen and (max-width: 820px) and (min-height: 1180px) {
                width: 100%;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }

    &__block6 {
        &-title {
            width: 63%;
            margin: auto;
            font-weight: 900;
            font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 1.3393))), 50px);
            text-align: start;
            font-family: $CeraBlack;
            text-transform: uppercase;
            @media screen and (max-width: 768px) {
                width: 90%;
            }
            @media screen and (max-width: 330px) {
                width: 50%;
                margin: 0;
                margin-left: 10%;
                font-family: $CeraBlack;
                font-weight: 900;
                overflow: inherit;
                line-height: 35px;
            }
        }
        &__swiper {
            margin-top: 4.3%;
            @media screen and (max-width: 768px) {
                margin-top: 8%;
            }
            &-box {
                width: 100%;
                height: 33.66vw;
                @media screen and (max-width: 768px) {
                    height: 320px;
                }
            }
        }
    }
}
</style>