<template>
    <div class="cardNews">
        <div class="cardNews__block1">
            <span class="desctop">
                <div class="cardNews__block1-back" @click="$router.back">
                    <img src="@/assets/icons/backArrow.png"/>
                    <p>{{ $t('back') }}</p>
                </div>
            </span>
           <p class="cardNews__block1-desc desctop">{{ $t('newsOne') }}</p>
           <p class="cardNews__block1-text">{{ newsViewApi?.news?.title }}</p>
           <span class="desctop cardNews__block1__filter">
                <div class="cardNews__block1__filter-box" v-for="(item, index) in newsViewApi?.thematic_news" :key="index">
                    <div></div>
                    <p>{{ item?.title }}</p>
                </div>
           </span>
        </div>
        
        <div class="cardNews__block2" :style="{ backgroundImage: 'url(\'' + newsViewApi?.news?.image + '\')' }">
        </div>
        
        <div class="cardNews__block3">
            <div class="cardNews__block3__desc" v-html="newsViewApi?.news?.content">
            </div>

            <div class="cardNews__block3__social">
                <p>{{ $t('share') }}:</p>
                <div class="cardNews__block3__social__content">
                    <div class="cardNews__block3__social__content__box" @click="webShareApiSupported">
                        <img src="@/assets/icons/facebookNews.png"/>
                    </div>
                    <div class="cardNews__block3__social__content__box" @click="webShareApiSupported">
                        <img src="@/assets/icons/linkNews.png"/>
                    </div>
                    <div class="cardNews__block3__social__content__box" @click="webShareApiSupported">
                        <img src="@/assets/icons/telegaNews.png"/>
                    </div>
                    <div class="cardNews__block3__social__content__box" @click="webShareApiSupported">
                        <img src="@/assets/icons/twiterNews.png"/>
                    </div>
                    <div class="cardNews__block3__social__content__box" @click="webShareApiSupported">
                        <img src="@/assets/icons/whatsappNews.png"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="cardNews__block4 desctop">
            <p class="cardNews__block4-title">{{ $t('otherNewses') }}</p>
            <swiper
                class="cardNews__block4__swiper"
                :lazy="true" :loop="false"
                :mousewheel="true"
                :breakpoints="{
                    1400: {
                    slidesPerView: 2,
                    spaceBetween: 1
                    },
                    768: {
                    slidesPerView: 2,
                    spaceBetween: 1
                    }
                }"
                :speed="1200"
                :autoplay="{
                    delay: 2500
                }"
                >
                <swiper-slide  v-for="(item) in news" :key="item" >
                    <NewsCardMini :newsMiniValue="item" class="cardNews__block4__swiper-box"/>
                </swiper-slide>
            </swiper>
        </div>

        <div class="block6Mobile mobile" v-if="allWorksNews">
            <p class="cardNews__block4-title">{{ $t('otherNewses') }}</p>
            <div class="block6Mobile__card"  v-if="allWorksNews[0]" :style="{ backgroundImage: 'url(\'' + allWorksNews[0]?.image + '\')' }">
                <router-link :to="'/news/' + allWorksNews[0]?.slug" class="block6Mobile__card-box">
                    <p  v-html="allWorksNews[0]?.title"></p>
                    <img src="@/assets/icons/arrow90deg.png"/>
                </router-link>
            </div>

            <div class="block6Mobile__card"  v-if="allWorksNews[1]" :style="{ backgroundImage: 'url(\'' + allWorksNews[1]?.image + '\')' }">
                <router-link :to="'/news/' + allWorksNews[1]?.slug" class="block6Mobile__card-box">
                    <p  v-html="allWorksNews[1]?.title"></p>
                    <img src="@/assets/icons/arrow90deg.png"/>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide,} from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"; 

import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination } from 'swiper';
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay])
import NewsCardMini from '@/components/NewsCardMini.vue';


import { useHead } from "@vueuse/head"
import { computed, reactive } from "vue"
import { mapMutations } from 'vuex'

export default {
    components: { Swiper, SwiperSlide, NewsCardMini },
    data() {
        return {
            news: [],
            allWorksNews: [],
            siteData: null,
            newsViewApi: "",
            titleMeta: "",
            descMeta: "",
            keywordsMeta: ""
        }
    },

     methods: {
        ...mapMutations(["SET_LOADING"]),

        webShareApiSupported() {
            const currentUrl = window.location.href;
            return navigator.share({
                title: "",
                text: "",
                url: currentUrl,
            });
         },
        
        initializeSiteData() {
            this.siteData = reactive({
                title: this.titleMeta,
                description: this.descMeta,
                keywords: this.keywordsMeta
            });

            useHead({
                meta: [
                    {
                        name: computed(() => 'title'),
                        content: computed(() => this.siteData.title),
                    },
                    {
                        name: computed(() => 'description'),
                        content: computed(() => this.siteData.description),
                    },
                    {
                        name: computed(() => 'keywords'),
                        content: computed(() => this.siteData.keywords),
                    },
                ]
            });
        },


        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
        }
    },
    watch: {
        $route() {
            this.SET_LOADING(true)
            this.$axios.post(`/V1/page/news/${this.$route.params.slug}`)
            .then((response) => {
                this.newsViewApi = response.data;
                this.titleMeta = response.data.title;
                this.descMeta = response.data.meta_description;
                this.keywordsMeta = response.data.meta_keywords;
                this.SET_LOADING(false)
            })
            .catch((e) => {
                this.SET_LOADING(false)
            });
        }
    },

    async created() {
      this.SET_LOADING(true)
      await this.$axios.post(`/V1/page/news/${this.$route.params.slug}`)
      .then((response) => {
        this.newsViewApi = response.data;
        this.titleMeta = response.data.meta_keywords;
        this.descMeta = response.data.meta_description;
        this.SET_LOADING(false)
      })
      .catch((e) => {
          this.SET_LOADING(false)
      });

    this.SET_LOADING(true)
    await this.$axios.get(`/V1/page/news`)
    .then((response) => {
        this.news = response.data.news;

        this.allWorksNews = response.data.news;
        this.shuffleArray(this.allWorksNews);

        this.SET_LOADING(false)
    })
    .catch((e) => {
        this.SET_LOADING(false)
    });
      
      await this.initializeSiteData();
    }
}
</script>
<style lang="scss" scoped>

.block6Mobile {
   &__card {
        margin-top: 2px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        &:nth-child(2) {
            margin-top: 32px;
        }
        &::before {
            position: absolute;
            content: "";
            z-index: 1;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg,rgb(0, 0, 0, 0.8),transparent 70%);
        }

        &-box {
            padding: 0 44px 29px 39px;
            height: 100vw;
            width: 100%;
            position: relative;
            z-index: 2;
            display: flex;
            align-items: flex-end;
            gap: 30px;
            justify-content: space-between;
            color: white;
            font-weight: 900;
            font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 0.5357))), 30px);
            p {
                text-shadow: 4px 2px 6px rgba(0,0,0,0.5);
                transition: all 0.5s ease;
            }
            img {
                width: 23px;
                height: 23px;
                object-fit: contain;
            }
        }
   }
}
.cardNews {
    padding-bottom: 146px;
    @media screen and (max-width: 768px) {
        padding-bottom: 220px;
    }
    &__block1 {
        height: fit-content;
        min-height: 28.58vw;
        margin: auto;
        display: flex;
        padding-top:  141px;
        flex-direction: column;
        @media screen and (max-width: 1152px) {
            padding-top: 90px
        }
        @media screen and (max-width: 1024px) {
            padding-top: 9.2% !important;
        }
        @media screen and (max-width: 768px) {
            padding-top:  85px !important;
            padding-bottom: 20px;
            justify-content: center;
            min-height: 303px;
            height: fit-content;
            padding-top: 37%;
        }
        &-back {
            cursor: pointer;
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 7px 13px;
            border: 1px solid #EB2127;
            border-radius: 49px;
            font-weight: 400;
            font-size: 14px;
            margin-left: 11%;
            @media screen and (max-width: 1200px) {
                position: relative;
                top: 21px;
            }
            img {
                transition: all 0.5s ease;
            }
            &:hover {
                transition: all 0.5s ease;
                background: #EB2127;
                color: white;
                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(9%) saturate(249%) hue-rotate(272deg) brightness(117%) contrast(100%);
                }
            }
        }
        &-desc {
            overflow: inherit;
            padding-top: 11px;
            color: #B3B3B3;
            text-align: center;
            font-weight: 400;
            font-size: 18px;
        }
        &-text {
            overflow: inherit;
            padding: 30px 0 78px 0;
            text-transform: uppercase;
            text-align: center;
            font-weight: 900;
            font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 1.3393))), 50px);
            font-family: $CeraBlack;
            font-weight: 900;
            @media screen and (max-width: 1024px) {
                padding: 0.5% 0 1.2% 0 !important;
            }
        }
        &__filter {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 20px;
            row-gap: 10px;
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 50px;
            @media screen and (max-width: 768px) {
                margin-bottom: 0;
            }
            &-box {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                &:nth-child(1) {
                    div {
                        display: none;
                    }
                }
                div {
                    border-radius: 50%;
                    height: 8px;
                    width: 8px;
                    background: #EB2127;
                }
            }
        }
    }
    &__block2 {
        height: fit-content;
        min-height: 457px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media screen and (max-width: 1240px) {
            height: fit-content;
            min-height: 28.58vw;
        }
        @media screen and (max-width: 768px) {
            height: 245px;
            background-position: center;
        }
    }

    &__block3 {
        width: 43.89%;
        margin: auto;
        padding-bottom: 8%;
        @media screen and (max-width: 768px)  {
            width: auto;
            margin-top: 50px;
            margin-left: 1rem;
            margin-right: 0.875rem;
        }
        &__desc {
            padding: 6.3% 0;
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
            @media screen and (max-width: 768px) {
                padding: 0 0 6.3% 0;
            }
        }
        &__social {
            display: flex;
            align-items: center;
            gap: 15px;
            font-weight: 400;
            font-size: 16px;
            color: #BDBDBDCC;
            @media screen and (max-width: 768px)  {
                flex-direction: column;
                align-items: start;
            }
            &__content {
                display: flex;
                align-items: center;
                gap: 12px;
                &__box {
                    cursor: pointer;
                    height: 28px;
                    width: 28px;
                    display: flex;
                    align-items: center;
                    border-radius: 50%;
                    border: 1px solid #BDBDBDCC;
                    justify-content: center;
                }
            }
        }
    }

    &__block4 {
        &-title {
            width: 60.4%;
            margin: auto;
            font-weight: 900;
            font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 1.3393))), 50px);
            text-align: start;
            font-family: $CeraBlack;
            text-transform: uppercase;
            @media screen and (max-width: 768px)  {
                width: 90%;
            }
        }
        &__swiper {
            margin-top: 4.3%;
            &-box {
                width: 100%;
                height: 33.66vw;
                @media screen and (max-width: 768px) {
                    height: 320px;
                }
            }
        }
    }
    
}
</style>