<template>
    <div class="contacts">
        <div class="contacts__block1">
            <p>{{ contacts.title }}</p>
        </div>
        <div class="contacts__block2">
            <div class="contacts__block2__left">
                <img src="@/assets/icons/gps.png"/>
                <div v-for="(item, index) in contacts.address" :key="index">
                    <p v-html="item.address"></p>
                </div>
            </div>
            <div class="contacts__block2__right">
                <img src="@/assets/icons/phone.png"/>
                <div class="contacts__block2__right__info">
                    <a :href="'tel:' + item.link" class="contacts__block2__right__info-box" v-for="(item, index) in contacts.phone" :key="index">
                        <p>{{ item.phone }}</p>
                        <div v-if="index + 1 < contacts.phone.length"></div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex';
import { useHead } from "@vueuse/head"
import { computed, reactive } from "vue"
export default {
    data() {
        return {
            siteData: null,
            contacts: "",
            titleMeta: "",
            descMeta: "",
            keywordsMeta: ""
        }
    },

    methods: {
        ...mapMutations(["SET_LOADING"]),

        
        initializeSiteData() {
            this.siteData = reactive({
                title: this.titleMeta,
                description: this.descMeta,
                keywords: this.keywordsMeta
            });

            useHead({
                meta: [
                    {
                        name: computed(() => 'title'),
                        content: computed(() => this.siteData.title),
                    },
                    {
                        name: computed(() => 'description'),
                        content: computed(() => this.siteData.description),
                    },
                    {
                        name: computed(() => 'keywords'),
                        content: computed(() => this.siteData.keywords),
                    },
                ]
            });
        },

    },
    

    async created() {
      this.SET_LOADING(true)
      await this.$axios.get(`/V1/page/contacts`)
      .then((response) => {
        this.contacts = response.data;
        this.titleMeta = response.data.title;
        this.descMeta = response.data.meta_description;
        this.keywordsMeta = response.data.meta_keywords;
        this.SET_LOADING(false)
      })
      .catch((e) => {
          this.SET_LOADING(false)
      });
      
      await this.initializeSiteData();
    }
}
</script>
<style lang="scss" scoped>
.contacts {
    padding-bottom: 145px;
    @media screen and (max-width: 768px)  {
        padding-bottom: 240px;
    }
    @media screen and (max-width: 454px)  {
        padding-bottom: 160px;
    }
    &__block1 {
        height: fit-content;
        min-height: 28.58vw;
        padding-top:  13.188rem;
        margin: auto;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        font-weight: 900;
        font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 1.3393))), 50px);
        p {
            overflow: inherit;
        }
        @media screen and (max-width: 1240px) {
            padding-top: 15.38vw;
        }
        @media screen and (max-width: 768px) {
            min-height: 357px;
            height: fit-content;
            padding-top: 158px;
        }
        p {
            font-family: $CeraBlack;
            font-weight: 900;
        }
    }

    &__block2 {
        height: fit-content;
        min-height: 33.58vw;
        display: flex;
        border-top: 0.5px solid #dfdfdf;
        border-bottom: 0.5px solid #dfdfdf;
        font-weight: 400;
        font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 0.5357))), 30px);
        color: #151515;
        @media screen and (max-width: 768px) {
            flex-direction: column;
            height: fit-content;
        }
        &__left {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            border-right: 0.5px solid #dfdfdf;
            p {
               text-align: center;
            }
            @media screen and (max-width: 820px) and (min-height: 1180px) {
                padding-bottom: 75px;
            }
            @media screen and (max-width: 1024px) {
                p {
                    width: 90%;
                    margin-right: auto;
                    margin-left:auto;
                }
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                justify-content: center;
                text-align: center;
                border-bottom: 0.5px solid #dfdfdf;
                padding-bottom: 90px;
                p {
                    width: 77%;
                }
            }
            img {
                margin: 87px 0;
                height: 61px;
                @media screen and (max-width: 768px) {
                    height: 42px;
                    margin: 81px 0 33px 0;
                }
            }
            div {
                display: flex;
                align-items: center;
                gap: 42px;
                @media screen and (max-width: 768px) {
                    flex-direction: column;
                    gap: 30px;
                }
                div {
                    height: 8px;
                    width: 8px;
                    background: #EB2127;
                    border-radius: 50%;
                }
            }
        }
        &__right {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            border-right: 0.5px solid #dfdfdf;
            @media screen and (max-width: 768px) {
                width: 100%;
                padding-bottom: 90px;
            }
            img {
                margin: 87px 0;
                height: 61px;
                object-fit: contain;
                @media screen and (max-width: 768px) {
                    height: 42px;
                    margin: 81px 0 42px 0;
                }
            }
            &__info {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                gap: 42px;
                @media screen and (max-width: 1024px) {
                    gap: 15px;
                    row-gap: 5px;
                }
                @media screen and (max-width: 768px) {
                    flex-direction: column;
                    gap: 30px;
                }
                &-box {
                    display: flex;
                    align-items: center;
                    gap: 42px;
                    @media screen and (max-width: 1024px) {
                        gap: 15px;
                        row-gap: 5px;
                    }
                    @media screen and (max-width: 768px) {
                        gap: 32px;
                        flex-direction: column;
                    }
                    div {
                        height: 8px;
                        width: 8px;
                        background: #EB2127;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

}
</style>