<template>
    <div class="news">
        <div class="news__block1">
            <p>{{ newsApi?.title }}</p>
        </div>
        <div class="news__block2__content" id="news__block2__content">
            <news-card v-for="item in paginatedNews" :key="item" :newsValue="item"/>
        </div>
        <div class="news__block3">
            <vue-awesome-paginate 
            v-if="news.length > 3" 
            :total-pages="totalPages" 
            :limit="3" 
            v-model="currentPage" 
            :items-per-page="3" 
            :total-items="(news.length).toFixed(1)" 
            :disabled-class="'disabled'" 
            :on-click="pageChanged"
            />
        </div>
    </div>
</template>
<script>

import NewsCard from '@/components/NewsCard.vue'
import { useHead } from "@vueuse/head"
import { computed, reactive } from "vue"
import { mapMutations } from 'vuex'

export default {
  components: { NewsCard },
    data() {
        return {
            news: [],
            currentPage: 1,
            timer: null,
            manualPageChange: true,
            siteData: null,
            newsApi: "",
            titleMeta: "",
            descMeta: "",
            keywordsMeta: ""
        }
    },
    computed: { 
        paginatedNews() { 
            const startIndex = (this.currentPage - 1) * 3; 
            const endIndex = startIndex + 3; 
            return this.news.slice(startIndex, endIndex); 
        }, 
        totalPages() { 
            return Math.ceil(this.news.length / 3); 
        }, 
    },
    watch: {
        paginatedNews() {
            document.getElementById('news__block2__content')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },

    },


    async created() {
        
        this.loadProduct();
        // this.timer = setInterval(this.changePage, 4000);
        
        this.SET_LOADING(true)
        await this.$axios.get(`/V1/page/news`)
        .then((response) => {
            this.newsApi = response.data;
            this.news = response.data.news;
            this.titleMeta = response.data.title;
            this.descMeta = response.data.meta_description;
            this.keywordsMeta = response.data.meta_keywords;
            this.SET_LOADING(false)
        })
        .catch((e) => {
            this.SET_LOADING(false)
        });
        
        await this.initializeSiteData();
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: { 
        ...mapMutations(["SET_LOADING"]),


        loadProduct() {
            if (this.$route.query.page) {
                this.pageChanged(this.$route.query.page);
            }
        },


        initializeSiteData() {
            this.siteData = reactive({
                title: this.titleMeta,
                description: this.descMeta,
                keywords: this.keywordsMeta
            });

            useHead({
                meta: [
                    {
                        name: computed(() => 'title'),
                        content: computed(() => this.siteData.title),
                    },
                    {
                        name: computed(() => 'description'),
                        content: computed(() => this.siteData.description),
                    },
                    {
                        name: computed(() => 'keywords'),
                        content: computed(() => this.siteData.keywords),
                    },
                ]
            });
        },

        pageChanged(page) { 
            this.currentPage = page;
            this.manualPageChange = false;
            this.$router.push(`/news?page=${page}`)
        },


        changePage() {
            if (this.manualPageChange) {
                this.currentPage++;
                if (this.currentPage > this.totalPages) {
                    this.currentPage = 1; 
                }
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.new-class {
    animation: zoomIN 2.2s infinite ease;
    transition: all 2.2s ease;
}

@keyframes zoomIN {
    0% {
        opacity: 0.8;
        transform: scale(0.8);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}



.news {
    padding-bottom: 235px;
    @media screen and (max-width: 768px)  {
    padding-bottom: 200px;
    }
    &__block1 {
        height: fit-content;
        min-height: 28.58vw;
        padding-top:  13.188rem;
        margin: auto;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        font-weight: 900;
        font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 1.3393))), 50px);
        p {
            overflow: inherit;
        }
        @media screen and (max-width: 1240px) {
            padding-top: 15.38vw;
        }
        @media screen and (max-width: 768px) {
            min-height: 357px;
            height: fit-content;
            padding-top: 158px;
        }
        p {
            font-family: $CeraBlack;
            font-weight: 900;
        }
    }
    &__block2 {
        &__content {
            transform: scale(1);
            opacity: 1;
        }
    }
    &__block3 {
        margin-left: 7%;
        margin-top: 63px;
        display: flex;
        gap: 32px;
        font-weight: 700;
        font-size: 14px;
        color: #BDBDBD;
        @media screen and (max-width: 768px) {
            margin: inherit;
            width: auto;
            margin-left: 1.625rem;
            margin-top: 20px;
            padding-right: 1.625rem;
        }
        p {
            cursor: pointer;
        }
    }
}
</style>