<template>
    <div class="news__block2__content__box" @click="$router.push('/news/' + newsValue.slug)">
        <div class="news__block2__content__box__left">
            <div class="news__block2__content__box__left-in">
                <p class="news__block2__content__box__left-p1">{{ newsValue.date }}</p>
                <p class="news__block2__content__box__left-p2" v-html="formattedTitle"></p>
                <div class="news__block2__content__box__left-p3">
                    <p v-html="newsValue.content"></p>
                </div>

                <span class="desctop">
                    <img src="@/assets/icons/arrow90deg.png" class="news__block2__content__box__left-p4"/>
                </span>
            </div>
        </div>
        <div class="news__block2__content__box__right">
            <v-lazy-image :src="newsValue.image" v-if="newsValue.image"/>
        </div>
    </div>
</template>
<script>
import VLazyImage from "v-lazy-image";
export default {
    components: {VLazyImage},
    props: ['newsValue'],

    computed: {
        formattedTitle() {
            const words = this.newsValue.title.split(" ");

            if (words.length > 1) {
                return words[0] + "<br>" + words.slice(1).join(" ");
            }

            return this.newsValue.title;
        }
    },

   mounted() {
        const paragraphs = document.querySelectorAll('.news__block2__content__box__left-p3');

        let maxHeight = 0;

        var pageWidth = document.documentElement.scrollWidth;
        var pageHeight = window.innerHeight;
        
        if (pageWidth < 820 && pageHeight > 1180) {
            maxHeight = 63;
        }
        
        else if (pageWidth > 768) {
            maxHeight = 69;
        }
        
        else if (pageWidth >= 390) {
             maxHeight = 96;
        }
        
        else if (pageWidth <= 280) {
            maxHeight = 97;
        }

        else {
            maxHeight = 97;
        }

        paragraphs.forEach(paragraph => {
            const paragraphHeight = paragraph.offsetHeight;
            const paragraphWidth = paragraph.offsetWidth;
            if (paragraphHeight > maxHeight) {
                paragraph.style.height = maxHeight + 'px';
                paragraph.style.overflow = 'hidden';

                const text = paragraph.textContent;
                let slicedText;

                if (pageWidth <= 820 & pageHeight >= 1180) {
                    slicedText = text.slice(0, text.lastIndexOf(' ', 90)) + '...';
                }

                else if (pageWidth > 768) {
                    slicedText = text.slice(0, text.lastIndexOf(' ', 161)) + '...';
                }

                else if (pageWidth >= 390){
                    slicedText = text.slice(0, text.lastIndexOf(' ', 185)) + '...';
                }
                
                else if (pageWidth >= 280) {
                    slicedText = text.slice(0, text.lastIndexOf(' ', 120)) + '...';
                }

                else {
                    slicedText = text.slice(0, text.lastIndexOf(' ', 100)) + '...';
                }
                paragraph.textContent = slicedText;
            }
            else {

            }
        });
    }
}
</script>
<style lang="scss" scoped>
.knowMore {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    margin-top: 23px;
}
.news__block2__content__box {
    border-top: 0.5px solid #dfdfdf;
    border-bottom: 0.5px solid #dfdfdf;
    height: 33.66vw;
    display: flex;
    overflow: hidden;
    @media screen and (max-width: 820px) and (min-height: 1180px) {
        height: 40vw;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        height: 100%;
    }

    &__left {
        width: 50%;
        position: relative;
        @media screen and (max-width: 768px)  {
            width: 100%;
        }
        &-p1 {
            color: #BDBDBDCC;
            font-weight: 400;
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
        }
        &-p2 {
            font-family: $CeraBlack;
            padding: 12% 0 3% 0;
            font-weight: 900;
            font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 0.5357))), 30px);
            @media screen and (max-width: 768px) {
                padding: 2.5% 0 2.5% 0;
            }
            @media screen and (max-width: 280px) {
                font-family: $CeraBlack;
                font-weight: 900;
                font-size: 15px;
            }
        }
        &-p3 {
            font-weight: 400;
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
            overflow: hidden;
        }
        &-p4 {
            position: absolute;
            bottom: 15%;
            right: 12.5%;
            filter: brightness(0) saturate(100%) invert(18%) sepia(63%) saturate(5218%) hue-rotate(348deg) brightness(96%) contrast(92%);
            @media screen and (max-width: 820px) and (min-height: 1180px) {
                bottom: 8%;
                right: 8%;
                width: 30px;
                height: 30px;
                object-fit: contain;
            }
        }
        &-in {
            padding: 8% 8% 4% 17%;
            @media screen and (max-width: 768px)  {
                padding: 8% 8% 8% 8%;
            }
        }
    }
    &__right {
        width: 50%;
        position: relative;
        @media screen and (max-width: 768px)  {
            width: 100%;
            height: 220px;
        }
        img {
            width: 100%;
            height: 100%;
            object-position: center;
            -o-object-position: center;
            object-fit: cover;
            transition: all 0.5s ease;
        }
        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            transition: all 0.5s ease;
            background: rgba(0, 0, 0, 0.1);
        }
    }
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}
</style>