<template>
    <div class="background animated fadeIn" v-scroll @click="closeCoop(false)">
        <div class="container">
            <div class="coop animated fadeInLogin" @click.stop>
                
                <!-- <img class="coop-redLine" src="@/assets/icons/vec.svg"/> -->

                <div class="coop__close" @click="closeCoop(false)">
                    <p>{{ $t('closeText') }}</p>
                    <img src="@/assets/icons/close.svg" class="desctop" />
                    <img src="@/assets/icons/close2.svg" class="mobile" />
                </div>
                <div class="coop__title">
                    <p class="coop__title-text" v-html="$t('filloutform')"></p>
                </div>
                <div class="coop__inputs">
                    <input type="text" placeholder="E-Mail *" v-model="v$.email.$model" />
                    <template v-for="(error) of v$.email.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>

                    <input type="text"  :placeholder="$t('yrName')  + ' *'" v-model="v$.name.$model" @keyup="onlyText()" />
                    <template v-for="(error) of v$.name.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>

                    <input type="text"  :placeholder="$t('yrPhone')  + ' *'" v-model="v$.phone.$model" v-mask="'+7 (###) ### ## ##'"
                        @input="isNumber" @accept="onAccept" @complete="onComplete" />
                    <template v-for="(error) of v$.phone.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>

                    <textarea  :placeholder="$t('yrMessage')" v-model="message"></textarea>
                </div>

                <label class="coop__label" for="file1">
                    <img src="@/assets/icons/doc.svg"/>
                    <input type="file" id="file1" @change="handleFileChange" 
                    ref="file"/>
                    <p v-if="!fileName.length">{{ $t('attresume') }}</p>
                    <p v-else class="fileName">{{ fileName }}</p>
                </label>

                <vue-recaptcha
                    theme="light"
                    size="normal"
                    :tabindex="0"
                    @widgetId="recaptchaWidget = $event"
                    @verify="canSignUp = true"
                />

                <div class="coop__button" @click="sendCooperation">
                    <p>{{ $t('sendText') }}</p>
                    <img src="@/assets/icons/arrowRight2.svg" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers, email } from "@vuelidate/validators";
import { mask } from "vue-the-mask";
import { IMaskDirective } from "vue-imask";

import { VueRecaptcha, useRecaptcha } from 'vue3-recaptcha-v2';
import { ref } from 'vue';

export default {
    setup: () => {
        const { resetRecaptcha } = useRecaptcha();
        const recaptchaWidget = ref(null);

        const callbackVerify = (response) => {
            console.log(response);
        };
        const callbackFail = () => {
            console.log("fail");
        };
        const actionReset = () => {
            resetRecaptcha(recaptchaWidget.value);
        };

        return {
            recaptchaWidget,
            callbackVerify,
            callbackFail,
            actionReset,
        };
    },
    directives: {
        imask: IMaskDirective,
        mask
    },
    components: { VueRecaptcha },
    data() {
        return {
            canSignUp: false,
            v$: useVuelidate(),
            email: "",
            name: "",
            phone: "",
            phoneMask: {
                mask: "+{7} ({000}) 000-00-00",
                lazy: true,
            },
            operators: [
                700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
                761, 762, 763, 764, 771, 775, 776, 777, 778,
            ],
            message: "",
            file: "",
            fileName: ""
        }
    },
    validations() {
        return {
            email: {
                required: helpers.withMessage(this.$t("reqField1"), required),
                email: helpers.withMessage(this.$t("req1"), email),
            },
            name: {
                required: helpers.withMessage(this.$t("reqField1"), required),
                minLength: helpers.withMessage(this.$t("req2"), minLength(3)
                ),
            },
            phone: {
                required: helpers.withMessage(this.$t("reqField1"), required),
                minLength: helpers.withMessage(this.$t("reqField1"), minLength(18)),
            }
        };
    },
    methods: {
        closeCoop(value) {
            this.$store.commit('SET_MODAL_RESUME', value);
        },
        CoopSuccess(value) {
            this.$store.commit('SET_MODAL_COOP__SUCCESS', value);
        },

        handleFileChange(event) {
            this.file = this.$refs.file.files['0'];

            const fileInput = event.target;
            if (fileInput.files.length > 0) {
                this.fileName = fileInput.files[0].name;
            } else {
                this.fileName = '';
            }
        },
        async sendCooperation() {
            let data = new FormData()
            data.append("email", this.email)
            data.append("name", this.name)
            data.append("phone", this.phone)
            data.append("message", this.message)
            data.append("resume", this.file)
            this.v$.$validate();
            if (!this.v$.$invalid && this.canSignUp) {
                await this.$axios.post("V1/feedback-vacancy", data)
                    .then(res => {
                        this.CoopSuccess(true);
                        this.closeCoop(false);
                        this.$toaster.success(this.$t('successSended'));
                    })
                    .catch(err => {
                        this.$toaster.error(this.$t('formzap'));
                    })
            }
            else {
                this.$toaster.error(this.$t('reqField1'));
            }
        },

        onlyText() {
            this.name = this.name.replace(/[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s.]/gi, "");
            this.name = this.name.replace(/\.{2,}|\s{2,}|\-{2,}/g, function (match) {
                return match.substr(0, 1);
            });
        },

        isNumber(e) {
            let regex = /[0-9]/;
            if (!regex.test(e.key)) {
                e.returnValue = false;
                if (e.preventDefault) e.preventDefault();
            }
            if (e.target.value.slice(4, 7).length === 3) {
                if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
                } else {
                    e.target.value = " ";
                    this.$toaster.error(this.$t('phoneisnotfound'));
                }
            }
        },
        onAccept(e) {
            const maskRef = e.detail;
            this.value = maskRef.value;
        },
        onComplete(e) {
            const maskRef = e.detail; // что бы в дальнейшем сохранить
        },
    }
}
</script>

<style lang="scss" scoped>
.fileName {
    width: 100%;
    font-size: 14px;
    overflow-wrap: break-word;
}
.background {
    overflow: auto;
    color: black;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.coop {
    margin: 30px 0;
    position: relative;
    width: 800px;
    background: #FCFCFD;
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08);
    box-sizing: border-box;
    padding: 25px 60px 150px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        width: 95%;
        padding: 25px 16px 45px 14px;
    }

    &-redLine {
        position: absolute;
        top: 45px;
        right: 45px;
        width: 34%;
        height: 29%;
        object-fit: contain;
        @media screen and (max-width: 768px) {
            top: 25px;
            right: 25px;
        }
    }

    &__label {
        cursor: pointer;
        margin-top: 15px;
        margin-bottom: 22px;
        width: 50.38%;
        display: flex;
        justify-content: flex-start;
        gap: 6px;
        align-items: center;
        margin-right: auto;
        margin-left: auto;
        font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1786))), 14px);
        input {
            display: none;
        }
        @media screen and (max-width: 768px) {
            width: 80.38%;
            margin-left: 0;
            
        }
    }
    &__close {
        position: relative;
        right: -5%;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        align-items: center;
        gap: 8px;
        color: #EB2127;
        font-size: 15px;
        font-weight: 700;
        font-size: min(max(10px, calc(0.625rem + ((1vw - 3.2px) * 0.4464))), 15px);
        @media screen and (max-width: 768px) {
            right: 0;
        }
    }

    &__title {
        width: 100%;
        margin-top: 25px;
        margin-bottom: 45px;
        position: relative;
        display: flex;
        @media screen and (max-width: 768px) {
            margin-top: 8px;
            margin-bottom: 40px;
        }
        @media screen and (max-width: 454px) {
            margin-top: 8px;
            margin-bottom: 60px;
        }
        @media screen and (max-width: 350px) {
            margin-top: 8px;
            margin-bottom: 40px; 
        }
        &-text {
            width: 60%;
            font-weight: 700;
            font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.4286))), 40px);
            font-family: $CeraBold;
            overflow: inherit;
            @media screen and (max-width: 768px) {
                width: 57%;
                line-height: 24px;
                overflow: inherit;
            }
            @media screen and (max-width: 454px) {
                 width: 60%;
            }
        }

    }

    &__inputs {
        position: relative;
        z-index: 1;
        width: 50.38%;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 25px;

        input,
        textarea {
            outline: none;
            padding: 15px 26px;
            border: 1px solid #000000;
            width: 100%;
            resize: none;
            font-size: 14px;
            @media screen and (max-width: 768px) {
                padding: 8.5px 18px;
                font-size: 10px;
            }
            @media screen and (max-width: 454px) {
                padding: 13.5px 18px;
                font-size: 10px;
            }
            @media screen and (max-width: 350px) {
                padding: 8.5px 18px;
                font-size: 10px;
            }
            &::placeholder {
                color: #A3A3A3;
            }
        }
        textarea {
            height: 82px;
            @media screen and (max-width: 768px) {
                height: 56px;
            }
        }
        @media screen and (max-width: 768px) {
            width: 100%;
            gap: 35px;
        }
        @media screen and (max-width: 454px) {
            width: 100%;
            gap: 25px;
        }
        @media screen and (max-width: 350px) {
            width: 100%;
            gap: 17px;
        }
    }

    &__button {
        cursor: pointer;
        width: 50.38%;
        margin: auto;
        margin-top: 22px;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 15px;
        padding: 14px 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #EB2127;
        font-size: min(max(10px, calc(0.625rem + ((1vw - 3.2px) * 0.4464))), 15px);
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 6.5px 20px;
            margin-top: 15px;
        }
        @media screen and (max-width: 454px) {
            width: 100%;
            padding: 13.5px 20px;
            margin-top: 15px;
        }
        @media screen and (max-width: 350px) {
            width: 100%;
            padding: 6.5px 20px;
            margin-top: 15px;
        }
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.fadeIn {
    animation-name: fadeIn;
}

.fadeInLogin {
    animation-name: fadeInLogin;
}

@keyframes fadeIn {
    from {
        top: -10%;
        opacity: 0;
    }

    to {
        top: 0%;
        opacity: 1;
    }
}

@keyframes fadeInLogin {
    from {
        transform: scale(0.5);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}
.errorValid {
    
  margin: -15px 0;
  @media screen and (max-width: 768px) {
    margin: -8px 0;
  }
  font-size: 14px !important;
  color: #EB2127 !important;
}
</style>