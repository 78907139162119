<template>
  <!-- MODALS -->
  <Cooperation v-if="getModalCoop"/>
  <CooperationSuccess v-if="getModalCoopSuccess"/>
  <Resume v-if="getModalResume"/>

  <!-- ////////////////////// -->


  <div class="loading Loader" v-show="getLoading">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
  </div>

  <div class="allSITE"  v-show="!getLoading">
    <Header />
    <div class="allSITE-router">
      <router-view class="main"/>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/layouts/Footer.vue';
import Header from '@/components/layouts/Header.vue';
import { mapGetters } from 'vuex';
import Cooperation from './components/modals/Cooperation.vue';
import CooperationSuccess from './components/modals/CooperationSuccess.vue';
import Resume from './components/modals/Resume.vue';

export default {
  components: { Header, Footer, Cooperation, CooperationSuccess, Resume },
  computed: {
    ...mapGetters(['getLoading', 'getModalCoop', 'getModalCoopSuccess', 'getModalResume'])
  },
}

</script>

<style lang="scss">
.allSITE {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  &-router {
    min-height: 700px;
  }
}

.Loader {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#app {
background: white;
width: 100vw;
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: stretch;
}

html,body,p, h1, h2, h3, h4, h5, h6 {
  overflow-x: hidden;
  margin: 0 ;
  padding: 0 ;
}

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: red;
}


.loading {
 --speed-of-animation: 0.9s;
 --gap: 6px;
 --first-color: #4c86f9;
 --second-color: #49a84c;
 --third-color: #f6bb02;
 --fourth-color: #f6bb02;
 --fifth-color: #2196f3;
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100px;
 gap: 6px;
 height: 100px;
}

.loading span {
 width: 4px;
 height: 50px;
 background: red;
 animation: scale var(--speed-of-animation) ease-in-out infinite;
}

.loading span:nth-child(2) {
 background: red;
 animation-delay: -0.8s;
}

.loading span:nth-child(3) {
 background: red;
 animation-delay: -0.7s;
}

.loading span:nth-child(4) {
 background: red;
 animation-delay: -0.6s;
}

.loading span:nth-child(5) {
 background: red;
 animation-delay: -0.5s;
}

@keyframes scale {
 0%, 40%, 100% {
  transform: scaleY(0.05);
 }

 20% {
  transform: scaleY(1);
 }
}


.bounce-enter-active {
  animation: bounce-in 1s;
}
.bounce-leave-active {
  animation: bounce-in 1s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.4);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}


</style>
