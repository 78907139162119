<template>
    <div class='agen__block2__box' @click="$router.push('/showcase/' + agencyValue.slug)">
        <img :src="agencyValue.logo" class='agen__block2__box-brands'/>
        <img src="@/assets/icons/arrow90deg.png" class="agen__block2__box-linker"/>
    </div>
</template>
<script>
export default {
    props: ['agencyValue']
}
</script>
<style lang="scss" scoped>
.agen__block2__box {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;
    height: 33.66vw;
    border: 0.5px solid #eeeeeecc;
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 320px;
    }
    &-brands {
        position: absolute;
        width: 42.22%;
        max-height: 42.75%;
        object-fit: contain;
        margin: auto;
        @media screen and (max-width: 768px)  {
            width: 56.85%;
            max-height: 54%;
            object-fit: contain;
            object-position: center;
        }
    }
    &-linker {
        position: absolute;
        bottom: 10%;
        right: 7%;
        filter: brightness(0) saturate(100%) invert(18%) sepia(63%) saturate(5218%) hue-rotate(348deg) brightness(96%) contrast(92%);
        @media screen and (max-width: 768px) {
            height: 23px;
            width: 23px;
            bottom: 40px;
            right: 44px;
            object-fit: contain;
        }
    }
}
</style>