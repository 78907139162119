<template>
    <div class="agen">
        <div class="agen__block1">
            <p class="agen__block1-title" v-html="agencyApi.title"></p>
            <span class="desctop agen__block1__desc">
                <div class="agen__block1__desc__content">
                    <div class="agen__block1__desc__content-box">
                        <div class="agen__block1__desc__content-box-all" @click="changePage(0, 0)">
                            <p>{{ $t('showcase.all') }}</p>
                            <img src="@/assets/icons/arrow90little.svg"/>
                        </div>
                        <div class="agen__block1__desc__content-box-div"></div>
                    </div>
                    <div class="agen__block1__desc__content-box" v-for="(item, index) in agencyApi?.show_case_page" :key="index" @click="changePage(item?.id, thematic_id)">
                        <p class="activeFilterDesctop" :class="{ 'activeFilterDesctop-active': item.id == page_id }">{{ item?.title }}</p> 
                        <div class="agen__block1__desc__content-box-div" v-if="agencyApi?.show_case_page?.length > index + 1"></div>
                    </div>
                </div>

                <div class="agen__block1__desc__content">
                    <div class="agen__block1__desc__content-box">
                        <div class="agen__block1__desc__content-box-all" @click="changePage(page_id, 0)">
                            <p>{{ $t('showcase.all') }}</p>
                            <img src="@/assets/icons/arrow90little.svg"/>
                        </div>
                        <div class="agen__block1__desc__content-box-div"></div>
                    </div>
                    <div class="agen__block1__desc__content-box" v-for="(item, index) in agencyApi?.thematic_show_case" :key="index" @click="changePage(page_id, item?.id)">
                        <p class="activeFilterDesctop" :class="{ 'activeFilterDesctop-active': item.id == thematic_id}">{{ item?.title }}</p>
                        <div class="agen__block1__desc__content-box-div" v-if="agencyApi?.thematic_show_case?.length > index + 1"></div>
                    </div>
                </div>
            </span>
            <div class="mobile">
                <div class="filterMob">
                    <div class="filterMob__box" v-click-outside="closeFilterOne">
                        <div class="filterMob__box-select" @click="openMobFilterOne = !openMobFilterOne" :style="openMobFilterOne ? 'z-index: 3' : 'z-index: 1'">
                            <img src="@/assets/icons/country.svg"/>
                            <p>{{ $t('countriesText') }}</p>
                            <img src="@/assets/icons/arrowMobile.svg" :style="openMobFilterOne ? 'rotate: 180deg' : 'rotate: 0deg'" :class="{activeFitler: openMobFilterOne == true }"/>
                        </div>
                        <transition name="filter">
                            <div class="filterMob__box__content" v-if="openMobFilterOne">
                                <div class="filterMob__box__content-box" @click="changePage(0, 0)" :class="{ activeFilterBox: 0 == page_id }">{{ $t('showcase.all') }}</div>
                                <div class="filterMob__box__content-box" @click="changePage(item?.id, thematic_id)" v-for="(item, index) in agencyApi?.show_case_page" :key="index + 'indx'" :class="{activeFilterBox: item.id == page_id}">{{ item?.title }}</div>
                            </div>
                        </transition>
                    </div>

                    <div class="filterMob__box" v-click-outside="closeFilterTwo">
                        <div class="filterMob__box-select" @click="openMobFilterTwo = !openMobFilterTwo" :style="openMobFilterTwo ? 'z-index: 3' : 'z-index: 1'">
                            <img src="@/assets/icons/notice.svg"/>
                            <p>{{ $t('agenciesText') }}</p>
                            <img src="@/assets/icons/arrowMobile.svg" :style="openMobFilterTwo ? 'rotate: 180deg' : 'rotate: 0deg'" :class="{ activeFitler: openMobFilterTwo == true }"/>
                        </div>
                        <transition name="filter">
                            <div class="filterMob__box__content" v-if="openMobFilterTwo">
                                <div class="filterMob__box__content-box" @click="changePage(page_id, 0)" :class="{ activeFilterBox: 0 == thematic_id }">{{ $t('showcase.all') }}</div>
                                <div class="filterMob__box__content-box" @click="changePage(page_id, item?.id)" v-for="(item, index) in agencyApi?.thematic_show_case" :key="index + 'indx'" :class="{ activeFilterBox: item.id == thematic_id }">{{ item?.title }}</div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>

        <div class="agen__block2" v-if="agency.length">
            <agency-card v-for="item in agency" :key="item" :agencyValue="item"/>
        </div>

        <div class="agen__block2Text" v-else>
            {{ $t('notFound') }}
        </div>
    </div>
</template>
<script>
import AgencyCard from '@/components/AgencyCard.vue'

import { useHead } from "@vueuse/head"
import { computed, reactive } from "vue"
import { mapMutations } from 'vuex'
export default {
  components: { AgencyCard },
    data() {
        return {
            openMobFilterOne: false,
            openMobFilterTwo: false,
            page_id: 0,
            thematic_id: 0,
            agency: [],
            siteData: null,
            agencyApi: "",
            titleMeta: "",
            descMeta: "",
            keywordsMeta: ""
        }
    },

    methods: {
        ...mapMutations(["SET_LOADING"]),

        closeFilterOne() {
            this.openMobFilterOne = false;
        },

        closeFilterTwo() {
            this.openMobFilterTwo = false;
        },

        initializeSiteData() {
            this.siteData = reactive({
                title: this.titleMeta,
                description: this.descMeta,
                keywords: this.keywordsMeta
            });

            useHead({
                meta: [
                    {
                        name: computed(() => 'title'),
                        content: computed(() => this.siteData.title),
                    },
                    {
                        name: computed(() => 'description'),
                        content: computed(() => this.siteData.description),
                    },
                    {
                        name: computed(() => 'keywords'),
                        content: computed(() => this.siteData.keywords),
                    },
                ]
            });
        },

        
        async changePage(page, thematic) {
            this.page_id = page;
            this.thematic_id = thematic;
            await this.$axios.post(`/V1/page/showcase/filter`, {
                page_id: this.page_id,
                thematic_id: this.thematic_id
            })
            .then((response) => {
                this.agency = response.data.show_cases;
            })
            .catch((e) => {
            });
        }
    },
    

    async created() {
      this.SET_LOADING(true)
      await this.$axios.get(`/V1/page/showcase`)
      .then((response) => {
        this.agencyApi = response.data;
        this.agency = response.data.show_cases;
        this.titleMeta = response.data.title;
        this.descMeta = response.data.meta_description;
        this.keywordsMeta = response.data.meta_keywords;
        this.SET_LOADING(false)
      })
      .catch((e) => {
          this.SET_LOADING(false)
      });
      
      await this.initializeSiteData();
    }
}
</script>
<style lang="scss" scoped>
.activeFilterDesctop {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        height: 2px;
        width: 0%;
        background: red;
        bottom: -2px;
        transition: all 0.5s ease;
    }
    &-active {
        &::before {
            content: "";
            position: absolute;
            width: 100%;
        }
    }
}
.activeFitler {
    filter: brightness(0) saturate(100%) invert(20%) sepia(77%) saturate(3839%) hue-rotate(346deg) brightness(93%) contrast(99%) !important;
}
.activeFilterBox {
    border-left: 8px solid red !important;
}
.filterMob {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    &__box {
    position: relative;
        &-select {
            padding: 0 22px 0 22px;
            position: relative;
            display: flex;
            align-items: center;
            gap: 16px;
            text-transform: uppercase;
            font-size: 15px;
            img {
                transition: all 0.5s ease;
            }
        }
        &__content {
            box-shadow: 0px 23px 32px 0px #00000033;
            width: 100%;
            z-index: 2;
            position: absolute;
            top: -70%;
            padding: 60px 22px 20px 0;
            background: #f2f2f2;
            display: flex;
            flex-direction: column;
            gap: 24px;
            &-box {
                transition: all 0.5s ease;
                border-left: 8px solid transparent;
                padding-left: 22px;
                font-weight: 400;
                font-size: 16px;
                &:nth-child(1) {
                    font-weight: 700;
                }
            }
        }
    }
}
.agen {
    padding-bottom: 136px;
    @media screen and (max-width: 768px) {
        padding-bottom: 200px;
    }
    &__block1 {
        height: fit-content;
        min-height: 28.58vw;
        padding-top:  13.188rem;
        margin: auto;
        display: flex;
        flex-direction: column;
         gap: 4%;
        p {
            overflow: inherit;
        }
        @media screen and (max-width: 1024px) {
            gap: 1%;
            padding-top: 12%;
            overflow: inherit;
        }
        @media screen and (max-width: 1240px) {
            padding-top: 15.38vw;
        }
        @media screen and (max-width: 768px) {
            min-height: 434px;
            height: fit-content;
            padding-top: 158px;
        }
        &-title {
            overflow: inherit;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            text-align: center;
            font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 1.3393))), 50px);
            font-family: $CeraBlack;
            font-weight: 900;
            padding-bottom: 44px;
            @media screen and (max-width: 768px) {
                padding-bottom: 24px;
            }

        }
        &__desc {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            @media screen and (max-width: 1024px) {
                gap: 10px;
            }
            &__content {
                display: flex;
                gap: 20px;
                &-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    p {
                        cursor: pointer;
                    }
                    &-all {
                        display: flex;
                        align-items: flex-end;
                        gap: 5px;
                        font-weight: 700;
                        img {
                            position: relative;
                            top: -3px;
                        }
                        p {
                            font-family: $CeraBlack;
                        }
                    }
                    &-div {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #EB2127;
                    }
                }
            }
            padding-bottom: 40px;
        }
    }
    &__block2Text {
        padding: 170px 0;
        border-top: 0.5px solid #eeeeeecc;
        border-bottom: 0.5px solid #eeeeeecc;
        text-align: center;
        font-weight: 900;
        font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 1.3393))), 50px);
    }
    &__block2 {
        border-top: 0.5px solid #eeeeeecc;
        border-bottom: 0.5px solid #eeeeeecc;
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: 768px) {
            flex-wrap: nowrap;
            flex-direction: column;
        }
        &__box {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-repeat: no-repeat;
            background-size: cover;
            width: 50%;
            height: 33.66vw;
            border: 0.5px solid #eeeeeecc;
            @media screen and (max-width: 768px) {
                width: 100%;
                height: 320px;
                // height: 36vh;
                // max-height: fit-content;
            }
            &-linker {
                position: absolute;
                bottom: 10%;
                right: 7%;
                filter: brightness(0) saturate(100%) invert(18%) sepia(63%) saturate(5218%) hue-rotate(348deg) brightness(96%) contrast(92%);
            }
        }
    }
}

.filter-enter-active {
  animation: filter-in 0.5s;
}
.filter-leave-active {
  animation: filter-in 0.5s reverse;
}

@keyframes filter-in {
  0% {
    // background: transparent;
    opacity: 0;
    top: -100%;
    transform: rotateX(50deg) scale(0.9);
  }
  100% {
    // background: white;
    opacity: 1;
    top: -70%;
    transform: rotateX(0) scale(1);
  }
}
</style>