import { createStore } from 'vuex'

export default createStore({
  state: {
    loading: false,
    lang: localStorage.getItem('lang') || "ru",
    ModalCoop: false,
    ModalCoopSuccess: false,
    ModalResume: false
  },
  getters: {
    getLoading: (state) => state.loading,
    getLang: (state) => state.lang,
    getModalCoop: (state) => state.ModalCoop,
    getModalCoopSuccess: (state) => state.ModalCoopSuccess,
    getModalResume: (state) => state.ModalResume
  },
  mutations: {
    SET_MODAL_COOP(state, value) {
      state.ModalCoop = value;
    },
    SET_MODAL_COOP__SUCCESS(state, value) {
      state.ModalCoopSuccess = value;
    },
    SET_MODAL_RESUME(state, value) {
      state.ModalResume = value;
    },

    SET_LOADING(state, value) {
      state.loading = value;
    },
    
     UPDATE_LANG(state, lang) {
        state.lang = lang
        localStorage.setItem("lang", lang);
    },
  },
  actions: {
  },
  modules: {
  }
})
