import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import AgenciesView from '../views/AgenciesView.vue'
import AgenciesViewSlug from '../views/AgenciesViewSlug.vue'
import CountriesView from '../views/CountriesView.vue'
import NewsView from '../views/NewsView.vue'
import NewsViewSlug from '../views/NewsViewSlug.vue'
import ContactsView from '../views/ContactsView.vue'
import WorkView from '../views/WorkView.vue'
import WorkViewSlug from '../views/WorkViewSlug.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about-us',
    name: 'AboutView',
    component: AboutView
  },
  {
    path: '/showcase',
    name: 'AgenciesView',
    component: AgenciesView
  },
  {
    path: '/showcase/:slug',
    name: 'AgenciesViewSlug',
    component: AgenciesViewSlug
  },
  {
    path: '/countries',
    name: 'CountriesView',
    component: CountriesView
  },
  {
    path: '/news',
    name: 'NewsView',
    component: NewsView
  },
  {
    path: '/news/:slug',
    name: 'NewsViewSlug',
    component: NewsViewSlug
  },
  {
    path: '/contacts',
    name: 'ContactsView',
    component: ContactsView
  },
  {
    path: '/our-works',
    name: 'WorkView',
    component: WorkView
  },
  {
    path: '/our-works/:slug',
    name: 'WorkViewSlug',
    component: WorkViewSlug
  },
  {
    path: '/:catchAll(.*)',
    name: "404",
    component: () => import("../views/NotFound.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Проверяем, есть ли у нас хэш-адрес в объекте to
    if (to.hash) {
      
        const el = document.querySelector(to.hash);
        if (el) {
        const scrollTop = el.getBoundingClientRect().top + window.pageYOffset - 85;
        window.scrollTo({
          top: scrollTop,
          behavior: 'smooth'
        });
        return null;
      }

    } else {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
      // return { top: 0 }
    }
  }
})

export default router
